<template>
    <div class="container">
        <div class="row mt-3">
            <div class="col">
                <h2>Anläggningar</h2>
            </div>
            <div class="col">
                <div class="float-top float-end">
                    <button class="btn btn-primary" v-if="isAdmin" @click="showCustomersModal()">Kunder</button>
                    <button class="btn btn-primary ms-3" v-if="isAdmin" @click="showEditModal(null)">Lägg till anläggning</button>
                </div>
            </div>
        </div>
        <div class="row">
            <table class="table table-bordered mt-3" style="background-color: white;">
                <thead>
                    <tr>
                        <th>Namn</th>
                        <th>Ort</th>
                        <th v-if="isAdmin"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="site in sites" :key="site.id">
                        <td>
                            {{ userLevel > 10 || site.name == site.customer_name ? '' : `${site.customer_name} / ` }}
                            <router-link :to="`/site/${site.id}`">{{ site.name }}</router-link>
                        </td>
                        <td>{{ site.address }}</td>
                        <td v-if="isAdmin" class="text-end">
                            <button class="btn btn-link btn-sm" @click="showEditModal(site.id)">Redigera</button>
                            <button class="btn btn-link btn-sm" @click="showDeleteModal(site.id)">Ta bort</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="modal" id="editModal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">{{ site.id ? 'Redigera anläggning' : 'Lägg till anläggning' }}</h4>
                        <button type="button" class="btn-close" aria-label="Close" @click="editModal.hide()"></button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div>
                                <label for="customerSelect" class="form-label">Kund</label>
                                <select id="customerSelect" class="form-select" v-model="site.customer_id">
                                    <option v-for="opt in extraCustomerOptions" :key="opt.value" :value="opt.value">{{ opt.label }}</option>
                                    <option v-for="customer in customers" :key="customer.id" :value="customer.id">{{ customer.name }}</option>
                                </select>
                                <div class="card bg-light mt-3" v-show="site.customer_id == 'new' || site.customer_id == 'auto'">
                                    <div class="card-body">
                                        <div>
                                            <label for="customerNameInput" class="form-label">Kundnamn</label>
                                            <input type="text" class="form-control" id="customerNameInput" v-model="customer.name" 
                                                :disabled="site.customer_id == 'auto'" 
                                                :placeholder="site.customer_id == 'auto' ? 'Samma som anläggning' : ''">
                                            <div class="text-danger" v-show="errors.customerNameEmpty">Vänligen ange namn</div>
                                            <div class="text-danger" v-show="errors.customerNameTaken">Namnet används redan på en annan kund</div>
                                        </div>
                                        <div class="mt-3">
                                            <label for="customerNumberInput" class="form-label">Kundnummer (frivilligt)</label>
                                            <input type="text" class="form-control" id="customerNumberInput" v-model="customer.number">
                                        </div>
                                    </div>
                                </div>
                                <div class="text-danger" v-show="errors.customerNotSelected">Vänligen välj kund eller välj att skapa ny</div>
                            </div>
                            <div class="mt-3">
                                <label for="nameInput" class="form-label">Anläggningsnamn</label>
                                <input type="text" class="form-control" id="nameInput" v-model="site.name">
                                <div class="text-danger" v-show="errors.siteNameEmpty">Vänligen ange namn</div>
                                <div class="text-danger" v-show="errors.siteNameTaken">Namnet används redan på en annan anläggning</div>
                            </div>
                            <div class="mt-3">
                                <label for="addressInput" class="form-label">Ort</label>
                                <input type="text" class="form-control" id="addressInput" v-model="site.address">
                            </div>
                            <div class="mt-3" v-show="site.id">
                                Egenskaper
                            </div>
                            <div class="card bg-light mt-2" v-show="site.id">
                                <div class="card-body">
                                    <div>
                                        <label for="maxResSelect" class="form-label">Max. upplösning statistik</label>&nbsp;
                                        <span class="text-muted" title="Den kortaste period-längden som skall kunna väljas under statistik.">
                                            <svg viewBox="64 64 896 896" focusable="false" data-icon="question-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"></path></svg>
                                        </span>
                                        <select id="maxResSelect" class="form-select" v-model="properties.maxStatsRes">
                                            <option value="15min">15 minuter</option>
                                            <option value="hour">Timme</option>
                                            <option value="day">Dag</option>
                                            <option value="month">Månad</option>
                                        </select>
                                    </div>
                                    <div class="form-check mt-3">
                                        <input id="showHeatedWaterCheck" type="checkbox" class="form-check-input" v-model="properties.showHeatedWater"/>
                                        <label for="showHeatedWaterCheck" title="">Visa hetvatten för boende</label>&nbsp;
                                        <span class="text-muted" title="I de fall det finns värmemätare som rapporterar in volym, visa denna i ett separat kort på boendesidan.">
                                            <svg viewBox="64 64 896 896" focusable="false" data-icon="question-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"></path></svg>
                                        </span>
                                    </div>
                                    <div class="form-check mt-3">
                                        <input id="customerReportsCheck" type="checkbox" class="form-check-input" v-model="properties.customerReports"/>
                                        <label for="customerReportsCheck" title="">Kund kan ta ut rapporter</label>&nbsp;
                                        <span class="text-muted" title="Rapport-fliken är tillgänglig för förvaltare.">
                                            <svg viewBox="64 64 896 896" focusable="false" data-icon="question-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"></path></svg>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div v-show="errorMessage" class="text-danger mt-3">{{ errorMessage }}</div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="editModal.hide()">Avbryt</button>
                        <button type="button" class="btn btn-primary" @click="submitEditModal">Spara</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="deleteModal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Ta bort anläggning</h4>
                        <button type="button" class="btn-close" aria-label="Close" @click="deleteModal.hide()"></button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div>
                                <label for="nameInput" class="form-label">Skriv in '{{ site.name }}' för att kunna ta bort anläggningen</label>
                                <input type="text" class="form-control" id="nameInput" v-model="deleteName">
                                <div class="text-danger" v-show="errors.deleteNameNotMatching">Det exakta namnet måste skrivas in</div>
                            </div>
                            <div class="form-check mt-3">
                                <input id="deleteCustomerCheck" type="checkbox" class="form-check-input" v-model="deleteCustomer"/>
                                <label for="deleteCustomerCheck">Ta också bort kund om den inte används</label>
                            </div>
                        </form>
                        <div v-show="errorMessage" class="text-danger mt-3">{{ errorMessage }}</div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="deleteModal.hide()">Avbryt</button>
                        <button type="button" class="btn btn-danger" id="deleteButton" @click="submitDeleteModal">Ta bort anläggning</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="customersModal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Kunder</h4>
                        <button type="button" class="btn-close" aria-label="Close" @click="customersModal.hide()"></button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <label for="customerModalSelect" class="form-label">Kund</label>
                            <select id="customerModalSelect" class="form-select" v-model="customer.id" @change="onCustomerChange">
                                <option value="new">-- Skapa ny kund --</option>
                                <option v-for="customer in customers" :key="customer.id" :value="customer.id">{{ customer.name }}</option>
                            </select>
                            <div class="card bg-light mt-3" v-show="this.customer.id">
                                <div class="card-body">
                                    <div>
                                        <label for="customerModalNameInput" class="form-label">Kundnamn</label>
                                        <input type="text" class="form-control" id="customerModalNameInput" v-model="customer.name">
                                        <div class="text-danger" v-show="errors.customerNameEmpty">Vänligen ange namn</div>
                                        <div class="text-danger" v-show="errors.customerNameTaken">Namnet används redan på en annan kund</div>
                                    </div>
                                    <div class="mt-3 mb-3">
                                        <label for="customerModalNumberInput" class="form-label">Kundnummer (frivilligt)</label>
                                        <input type="text" class="form-control" id="customerModalNumberInput" v-model="customer.number">
                                    </div>
                                    <button type="button" class="btn btn-danger" @click="onDeleteCustomer" v-show="deleteCustomer">Ta bort kund</button>
                                    <button type="button" class="btn btn-primary float-end" @click="onSubmitCustomer">{{ customer.id === 'new' ? 'Skapa kund' : 'Spara kund' }}</button>
                                </div>
                            </div>
                        </form>
                        <div v-show="errorMessage" class="text-danger mt-3">{{ errorMessage }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import customersService from '@/services/customersService'
import sitesService from '@/services/sitesService'
import tokenService from '../services/tokenService'
import helpers from '../helpers/helpers'
import { Modal } from 'bootstrap'

export default {
    data() {
        return {
            // Modals
            editModal: null,
            customersModal: null,
            deleteModal: null,
            // User
            isAdmin: false,
            userLevel: null,
            // Content data
            sites: [],
            customers: [],
            // Form data
            site: {
                id: null,
                customer_id: null,
                name: '',
                address: '',
            },
            extraCustomerOptions: [],
            customer: {
                id: null,
                name: '',
                number: ''
            },
            properties: {
                showHeatedWater: false,
                customerReports: false,
                maxStatsRes: 'hour'
            },
            removeUnusedCustomer: false,
            deleteName: '',
            deleteCustomer: false,
            // Validation
            errors: {
                siteNameEmpty: false,
                siteNameTaken: false,
                customerNotSelected: false,
                customerNameEmpty: false,
                customerNameTaken: false,
                deleteNameNotMatching: false
            },
            // Form error message
            errorMessage: null,
        }
    },
    methods: {
        showEditModal(siteId) {
            if (siteId) {
                const site = this.sites.find(s => s.id == siteId)
                this.site.id = site.id
                this.site.name = site.name
                this.site.customer_id = site.customer_id
                this.site.address = site.address
                this.properties.showHeatedWater = site.properties?.show_heated_water || false
                this.properties.customerReports = site.properties?.customer_reports || false
                this.properties.maxStatsRes = site.properties?.max_stats_resolution || 'hour'

                const customer = this.customers.find(c => c.id == site.customer_id)
                this.customer.id = customer.id
                this.customer.name = customer.name
                this.customer.number = customer.number

                this.extraCustomerOptions = []
            } else {
                this.site.id = null
                this.site.customer_id = null
                this.site.name = ''
                this.site.address = ''

                this.customer.id = null
                this.customer.name = ''
                this.customer.number = ''

                this.extraCustomerOptions = [
                    { value: 'auto', label: '-- Ny kund med samma namn som anläggning --' },
                    { value: 'new', label: '-- Ny kund med annat namn --' },
                ]
            }

            this.errorMessage = null
            this.errors.siteNameEmpty = false
            this.errors.siteNameTaken = false
            this.errors.customerNameTaken = false
            this.errors.customerNameEmpty = false
            this.errors.customerNotSelected = false
            
            this.editModal.show()
        },
        async submitEditModal() {
            let valid = true

            // Validate site
            this.errors.siteNameEmpty = false
            this.errors.siteNameTaken = false

            if (this.site.name.trim().length === 0) {
                this.errors.siteNameEmpty = true
                valid = false
            } else {
                const siteNamesake = this.sites.find(s => s.name == this.site.name.trim())
                
                if (siteNamesake && (!this.site.id || this.site.id != siteNamesake.id)) {
                    this.errors.siteNameTaken = true
                    valid = false
                }
            }

            // Validate customer
            this.errors.customerNotSelected = false
            this.errors.customerNameEmpty = false
            this.errors.customerNameTaken = false

            if (!this.site.customer_id) {
                this.errors.customerNotSelected = true
                valid = false
            } else if (this.site.customer_id == 'new' || this.site.customer_id == 'auto') {
                const customerName = this.site.customer_id == 'auto' ? this.site.name : this.customer.name

                if (customerName.trim().length === 0) {
                    this.errors.customerNameEmpty = true
                    valid = false
                } else {
                    const customerNamesake = this.customers.find(c => c.name == this.customer.name.trim())

                    if (customerNamesake && this.site.customer_id != this.customer.id) {
                        this.errors.customerNameTaken = true
                        valid = false
                    }
                }
            }

            if (!valid) {
                return
            }

            let customerId = this.site.customer_id
            let newCustomer = null

            if (customerId == 'new') {
                newCustomer = {
                    name: this.customer.name,
                    number: this.customer.number
                }
            } else if (customerId == 'auto') {
                newCustomer = {
                    name: this.site.name,
                    number: this.customer.number
                }
            }

            try {
                if (newCustomer) {
                    const res = await customersService.createCustomer(newCustomer)
                    this.customers.push({ id: res.data.id, ...newCustomer })
                    customerId = res.data.id
                    this.customers = this.customers.sort((a, b) => helpers.compareStrings(a.name, b.name))
                }

                const customerName = this.customers.find(c => c.id == customerId)?.name

                if (this.site.id) {
                    await sitesService.updateSite({ 
                        id: this.site.id,
                        customer_id: customerId,
                        name: this.site.name,
                        address: this.site.address,
                        properties: {
                            show_heated_water: this.properties.showHeatedWater,
                            customer_reports: this.properties.customerReports,
                            max_stats_resolution: this.properties.maxStatsRes
                        }
                    })
                    let site = this.sites.find(s => s.id == this.site.id)
                    site.customer_id = customerId
                    site.name = this.site.name
                    site.address = this.site.address
                    site.customer_name = customerName
                    site.properties = {
                        show_heated_water: this.properties.showHeatedWater,
                        customer_reports: this.properties.customerReports,
                        max_stats_resolution: this.properties.maxStatsRes
                    }
                } else {
                    const site = {
                        customer_id: customerId,
                        name: this.site.name,
                        address: this.site.address,
                    }
                    const res = await sitesService.createSite(site)
                    this.sites.push({ 
                        id: res.data.id, 
                        customer_name: customerName,
                        ...site,
                    })
                }

                this.sites = this.sites.sort((a, b) => { 
                    if (a.customer_name == b.customer_name) {
                        return helpers.compareStrings(a.name, b.name)
                    } else {
                        return helpers.compareStrings(a.customer_name, b.customer_name) 
                    }
                })

                this.editModal.hide()
            } catch (err) {
                console.log(err)
                helpers.getErrorMessage(err)
            }
        },
        showDeleteModal(siteId) {
            const site = this.sites.find(s => s.id == siteId)
            this.site = { ...site }
            this.deleteName = ''
            this.deleteCustomer = false
            this.errors.deleteNameNotMatching = false
            this.errorMessage = null
            this.deleteModal.show()
        },
        async submitDeleteModal() {
            if (this.deleteName == this.site.name) {
                this.errors.deleteNameNotMatching = false
                try {
                    await sitesService.deleteSite(this.site.id)
                    const siteIndex = this.sites.findIndex(s => s.id == this.site.id)
                    if (siteIndex >= 0) {
                        this.sites.splice(siteIndex, 1)
                    }

                    if (this.deleteCustomer) {
                        const site = this.sites.find(s => s.customer_id == this.site.customer_id)

                        if (!site) {
                            await customersService.deleteCustomer(this.site.customer_id)
                            const customerIndex = this.customers.findIndex(c => c.id == this.site.customer_id)

                            if (customerIndex >= 0) {
                                this.customers.splice(customerIndex, 1)
                            }
                        }
                    }

                    this.deleteModal.hide()
                } catch (err) {
                    helpers.getErrorMessage(err)
                }
            } else {
                this.errors.deleteNameNotMatching = true
            }
        },
        showCustomersModal() {
            this.customer = { id: 0, name: '', number: '' }
            this.errorMessage = null
            this.customersModal.show()
        },
        onCustomerChange() {
            if (this.customer.id === 'new') {
                this.customer.name = ''
                this.customer.number = ''
            } else {
                const customer = this.customers.find(c => c.id == this.customer.id)
                this.customer = { ...customer }
                const site = this.sites.find(s => s.customer_id == customer.id)
                this.deleteCustomer = !site
            }
        },
        async onDeleteCustomer() {
            try {
                await customersService.deleteCustomer(this.customer.id)

                const customerIndex = this.customers.findIndex(c => c.id == this.customer.id)

                if (customerIndex >= 0) {
                    this.customers.splice(customerIndex, 1)
                }

                this.customer = { id: null, name: '', number: '' }
            } catch (err) {
                helpers.getErrorMessage(err)
            }
        },
        async onSubmitCustomer() {
            this.errors.customerNameEmpty = false
            this.errors.customerNameTaken = false

            if (this.customer.name.trim()) {
                const customerNamesake = this.customers.find(c => c.name == this.customer.name.trim())

                if (customerNamesake && customerNamesake.id !== this.customer.id) {
                    this.errors.customerNameTaken = true
                    return
                }
            } else {
                this.errors.customerNameEmpty = true
                return
            }

            try {
                if (this.customer.id === 'new') {
                    const res = await customersService.createCustomer({
                        name: this.customer.name,
                        number: this.customer.number
                    })
                    const newCustomer = { 
                        id: res.data.id, 
                        name: this.customer.name, 
                        number: this.customer.number
                    }
                    this.customers.push({...newCustomer})
                    this.customer = {...newCustomer}
                } else if (this.customer.id) {
                    await customersService.updateCustomer({
                        id: this.customer.id,
                        name: this.customer.name,
                        number: this.customer.number,
                    })
                    let customer = this.customers.find(c => c.id == this.customer.id)
                    customer.name = this.customer.name
                    customer.number = this.customer.number
                }
                this.customers = this.customers.sort(helpers.compareStrings)
            } catch (err) {
                helpers.getErrorMessage(err)
            }
        },
    },
    async mounted() {
        this.editModal = new Modal(document.getElementById('editModal'))
        this.deleteModal = new Modal(document.getElementById('deleteModal'))
        this.customersModal = new Modal(document.getElementById('customersModal'))
        const user = tokenService.getUser()
        this.userLevel = user.level
        this.isAdmin = user.level == 1
        this.sites = (await sitesService.getSites())?.data
        if (user.level <= 10) {
            this.customers = (await customersService.getCustomers())?.data
        }
    },
    beforeRouteLeave(to, from, next) {
        this.editModal.hide()
        this.deleteModal.hide()
        this.customersModal.hide()
        next()
    },
}
</script>