import seriesHelpers from '@/helpers/seriesHelpers'
import api from './api'

class ReadingsService {
    getSiteLatestReadings(siteId) {
        return api.get(`/sites/${siteId}/latest_readings`)
    }

    getSiteMeasuringPointFields(siteId) {
        return api.get(`/sites/${siteId}/mp_fields`)
    }

    async getApartmentStatisticsData(apartmentId, data) {
        let params = {
            start: data.start.toISOString(),
            series: data.series,
            period: data.period,
            interval: data.interval,
        }

        const telegrams = (await api.get(`/apartments/${apartmentId}/statistics`, { params })).data

        return telegrams
    }

    async getApartmentStatistics(apartment) {
        const res = await api.get(`/tenant/apartments/${apartment}/month_statistics`)
        return res.data
    }

    async getSiteStatusStatistics(siteId, now) {
        return await api.get(`/sites/${siteId}/status_statistics`, { params: { now }})
    }

    getSiteStatistics(siteId, params) {
        return api.get(`/sites/${siteId}/statistics`, { params })
    }

    getDeviceReadings(deviceId, start, stop, noLimit = false) {
        let params = { start, stop }
        if (noLimit) {
            params.no_limit = 1
        }
        return api.get(`/devices/${deviceId}/readings`, { params })
    }
}

export default new ReadingsService()