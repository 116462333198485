<template>
    <div class="container tab-panel p-3">
        <div class="mb-3">
            <label for="reportSelect" class="form-label">Rapportmall</label>
            <select id="reportSelect" class="form-select" v-model="reportId" @change="onChangeReport">
                <option v-for="report in reports" :value="report.id">{{ report.name }}</option>
            </select>
        </div>
        <div v-show="reportId" class="alert alert-info">
            {{ report?.description }}
        </div>
        <div class="row">
            <div class="col-3" v-show="report?.fields.includes('date')">
                <label for="dateInput" class="form-label">Datum</label>
                <input id="dateInput" type="date" class="form-control" v-model="date">
            </div>
            <div class="col-3" v-show="report?.fields.includes('time')">
                <label for="timeInput" class="form-label">Tid</label>
                <input id="timeInput" type="time" class="form-control" v-model="time">
            </div>
        </div>
        <div class="mt-3">
            <button class="btn btn-primary" @click="onGenerateReport" :disabled="!reportId">Generera rapport</button>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import sitesService from '@/services/sitesService'
import writeXlsxFile from 'write-excel-file'

const route = useRoute()
const reportId = ref(null)
const report = ref(null)
const date = ref(new Date().toLocaleDateString('sv'))
const time = ref('00:00')

const reports = [
    {
        id: 'momreport',
        name: 'Momentanrapport',
        description: 'Tar ut ett Excel-ark med alla mätarställningar vid en viss tidpunkt, eller senaste dessförinnan. Varje mätslag hamnar i en egen flik.',
        fields: ['date', 'time'],
    }
]

const onChangeReport = () => {
    report.value = reports.find(r => r.id === reportId.value)
}

const onGenerateReport = () => {
    if (!report.value) return

    sitesService.getReport(route.params.id, report.value.id, { timestamp: new Date(date.value + 'T' + time.value) }).then(result => {
        const schema = []
        for (const sheet of result.data.schema) {
            const sheetSchema = []
            for (const s of sheet) {
                const column = {
                    column: s.column,
                    width: s.width,
                    header: { backgroundColor: '#ffff00' }
                }
                switch (s.type) {
                    case 'Number':
                        column.type = Number
                        column.value = data => isNaN(data[s.property]) ? null : Number(data[s.property])
                        break
                    default:
                        column.type = String
                        column.value = data => String(data[s.property] ?? '')
                        break
                }
                sheetSchema.push(column)
            }
            schema.push(sheetSchema)
        }

        writeXlsxFile(result.data.rows, {
            schema,
            sheets: result.data.sheets,
            headerStyle: {
                backgroundColor: '#cccccc',
                fontSize: 10,
                align: 'left'
            }, 
            fileName: `${report.value.name}_${date.value}_${time.value.replace(':', '_')}.xlsx`
        })
    })
}
</script>