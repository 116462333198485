<template>
    <div class="modal modal-xl" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Avläsningar</h4>
                    <button type="button" class="btn-close" aria-label="Close" @click="$emit('close')"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col">
                            <label for="apartmentSelect" class="form-label">Mätplats</label>
                            <select id="apartmentSelect" class="form-select" @change="$emit('change-apartment')" v-model="data.apartmentId"
                                :disabled="apartments.length === 0">
                                <option v-for="apartment in apartments" :key="apartment.id" :value="apartment.id">
                                    {{ apartment.number }}
                                </option>
                            </select>
                        </div>
                        <div class="col">
                            <label for="deviceSelect" class="form-label">Mätare</label>
                            <select id="deviceSelect" class="form-select" @change="$emit('update')" v-model="data.deviceId"
                                :disabled="data.devices.length === 0">
                                <option v-for="d in data.devices" :key="d.deviceId" :value="d.deviceId">
                                    {{ seriesHelpers.getMeasurementLabel(d.measurement) + ' ' + d.idNumber }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-5">
                            <label for="startInput" class="form-label">Från</label>
                            <VueDatePicker id="startInput" v-model="data.start" locale="sv" format="yyyy-MM-dd HH:mm" select-text="Välj" cancel-text="Avbryt" />
                        </div>
                        <div class="col-5">
                            <label for="stopInput" class="form-label">Till</label>
                            <VueDatePicker id="stopInput" v-model="data.stop" locale="sv" format="yyyy-MM-dd HH:mm" select-text="Välj" cancel-text="Avbryt" />
                        </div>
                        <div class="col-2">
                            <div>&nbsp;</div>
                            <button class="btn btn-primary mt-2" @click="$emit('update')">Ladda om</button>
                            &nbsp;
                            <button class="btn btn-secondary mt-2" @click="download" title="Ladda ner data"><i class="bi bi-download"></i></button>
                        </div>
                    </div>
                    <div class="mt-3">
                        Visar {{ data.readings.hasMore ? 'de senaste' : '' }} {{ data.readings.length }} avläsningar mellan valda tidpunkter. Senast först.
                    </div>
                    <div class="mt-3">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Tidpunkt</th>
                                    <th v-for="field in data.fields">
                                        <div class="text-end">
                                            {{ seriesHelpers.getFieldShortLabel(field) }}
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(reading, index) in data.readings" :key="index">
                                    <td>
                                        {{ new Date(reading.timestamp).toLocaleString('sv') }}
                                    </td>
                                    <td v-for="field in data.fields">
                                        <div class="text-end" :title="reading.fields[field]">
                                            {{ seriesHelpers.formatFieldValue(field, reading.fields[field]) }} {{ seriesHelpers.getFieldUnit(field) }}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="$emit('close')">Stäng</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import seriesHelpers from '@/helpers/seriesHelpers'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import readingsService from '@/services/readingsService'

const emit = defineEmits([ 'close', 'update', 'change-apartment' ])

const props = defineProps({
    data: Object,
    apartments: Array,
})

async function download() {
    if (!props.data.deviceId) {
        return
    }

    const data = (await readingsService.getDeviceReadings(
        props.data.deviceId, 
        props.data.start, 
        props.data.stop,
        true, // No limit
    )).data

    let columns = {}
    let columnIndex = 0
    let header = 'timestamp'
    const telegrams = []
    for (const timestamp in data) {
        const telegram = { 0: timestamp }
        telegrams.push(telegram)
        for (const field in data[timestamp]) {
            if (!(field in columns)) {
                columns[field] = ++columnIndex
                header += ';' + field
            }
            telegram[columns[field]] = data[timestamp][field]
        }
    }

    telegrams.sort((a, b) => a[0] < b[0] ? -1 : 1)

    const lines = [ header ]
    for (const telegram of telegrams) {
        const timestamp = new Date(telegram[0])
        let line = `${timestamp.toLocaleDateString('sv')} ${timestamp.toLocaleTimeString('sv')}`
        for (let i = 1; i <= columnIndex; i++) {
            line += ';'
            if (typeof telegram[i] !== 'undefined') {
                line += telegram[i]
            }
        }
        lines.push(line)
    }

    const device = props.data.devices.find(d => d.deviceId == props.data.deviceId)
    let deviceNumber = ''
    if (device) {
        deviceNumber = device.idNumber
    }

    const blob = new Blob([lines.join('\r\n')])
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = `Readings ${deviceNumber} ${props.data.start.toLocaleDateString('sv')}--${props.data.stop.toLocaleDateString('sv')}.csv`
    link.click()
    URL.revokeObjectURL(link.href)
}

</script>