<template>
    <div class="container text-center">
        <h2>{{ message }}</h2>
        <div>{{ errorCode }}</div>
        <div>{{ errorDescription }}</div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import invoiceDataService from '@/services/invoiceDataService'

const message = ref()
const errorCode = ref()
const errorDescription = ref()

const route = useRoute()
const router = useRouter()

invoiceDataService.fortnoxApiAuth(route.query.code, route.query.state).then((response) => {
    const site = Number.parseInt(route.query.state)
    if (!isNaN(site)) {
        router.push('/site/' + route.query.state)
    } else {
        message.value = 'Autentisering klar. Denna flik kan stängas.'
    }
}).catch(_err => {
    message.value = 'Autentisering misslyckades.'
    errorCode.value = 'Felkod från Fortnox: ' + route.query.error
    errorDescription.value = 'Felbeskrivning: ' + decodeURIComponent(route.query.error_description)
})
</script>