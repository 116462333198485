import axios from 'axios'
import api from './api'

class ImportService {
    async importReadings(data, filename, apiKey) {
        const importApi = axios.create({
            baseURL: process.env.VUE_APP_IMPORT_API ?? 'http://localhost:3500',
        })
        return await importApi.post('/import/v1/walkby', data, {
            headers: {
                'content-type': 'text/csv',
                'filename': filename,
                'api-key': apiKey,
            },
        })
    }

    updateImportCsvFormat(file, columns) {
        return api.post('/import_csv_formats', { file, columns })
    }
}

export default new ImportService()