<template>
    <div class="modal" id="uploadModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Ladda upp dokument</h4>
                    <button type="button" class="btn-close" aria-label="Close" @click="$emit('close')"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="mb-3">
                            <label for="formPeriod" class="form-label">År</label>
                            <select id="periodSelect" class="form-select" v-model="data.period">
                                <option v-for="period in periods" :key="period" :value="period">{{ period }}</option>
                            </select>
                            <div class="text-danger" v-show="data.missingPeriod">Vänligen välj ett år</div>
                        </div>
                        <div class="mb-3">
                            <label for="formCategory" class="form-label">Kategori</label>
                            <select id="categorySelect" class="form-select" v-model="data.category">
                                <option v-for="category in categories" :key="category" :value="category">{{ category }}</option>
                            </select>
                            <div class="text-danger" v-show="data.missingCategory">Vänligen välj en kategori</div>
                        </div>
                        <div class="mb-3">
                            <label for="formSubCategory" class="form-label">Underkategori</label>
                            <select id="subCategorySelect" class="form-select" v-model="data.subCategory">
                                <option v-for="subCategory in subCategories[data.category]" :key="subCategory" :value="subCategory">{{ subCategory }}</option>
                            </select>
                            <div class="text-danger" v-show="data.missingSubCategory">Vänligen välj en underkategori</div>
                        </div>
                        <div class="mb-3">
                            <label for="formFile" class="form-label">Välj fil</label>
                            <input class="form-control" type="file" id="docFileInput">
                            <div class="text-danger" v-show="data.noFileSelected">Vänligen välj en fil att ladda upp</div>
                        </div>
                        <div class="form-check mb-3">
                            <input id="formEmail" type="checkbox" class="form-check-input" v-model="data.sendEmail"/>
                            <label for="formEmail" class="form-label">Skicka e-post till {{ data.emailAddresses }}</label>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="$emit('close')">Avbryt</button>
                    <button type="button" class="btn btn-primary" @click="submit">Ladda upp</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import documentService from '@/services/documentsService'

export default {
    props: { data: Object },
    emits: [ 'submit', 'close', 'reload' ],
    data() {
        return {
            periods: [],
            categories: [
                'Kontroll ISO/IEC 17020:2012',
                'Kalibrering ISO/IEC 17025:2018',
            ],
            subCategories: {
                'Kontroll ISO/IEC 17020:2012': [
                    'Kallvatten',
                    'Varmvatten',
                    'Temperaturgivare',
                    'Integreringsverk',
                ],
                'Kalibrering ISO/IEC 17025:2018': [
                    'Massflöde',
                    'Volym',
                    'Kallvatten',
                    'Varmvatten',
                ]
            },
        }
    },
    methods: {
        async submit() {
            this.data.missingPeriod = false
            this.data.missingCategory = false
            this.data.missingSubCategory = false
            this.data.noFileSelected = false
            
            let valid = true

            if (!this.data.period) {
                valid = false
                this.data.missingPeriod = true
            }

            if (!this.data.category) {
                valid = false
                this.data.missingCategory = true
            }

            if (!this.data.subCategory) {
                valid = false
                this.data.missingSubCategory = true
            }

            const file = document.getElementById('docFileInput').files[0]
            if (!file) {
                this.data.noFileSelected = true
                valid = false
            }

            if (!valid) {
                return
            }
            
            this.$nextTick(() => { 
                this.$emit('close')
            })

            try {
                await documentService.uploadDocument({
                    siteId: this.data.siteId,
                    period: this.data.period,
                    category: this.data.category,
                    subCategory: this.data.subCategory,
                    sendEmail: this.data.sendEmail,
                    file
                })
                this.$emit('reload')    
            } catch (err) {
                alert('Fel vid uppladdning av fil (' + err.response?.status + ')')
            }
        },
    },
    mounted() {
        let now = new Date()
        let periods = []
        for (let i = 0; i < 15; i++) {
            periods.push((now.getFullYear() - i).toString())
        }
        this.periods = periods
    },
    watch: {
        data: {
            handler() {
                document.getElementById('docFileInput').value = ''
            }
        }
    },
}
</script>