<template>
    <div class="modal modal-lg" id="apiKeysModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">API-nycklar</h4>
                    <button type="button" class="btn-close" aria-label="Close" @click="$emit('close')"></button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row" v-show="keys.length > 0">
                            <div class="col-2"><strong>Nyckel</strong></div>
                            <div class="col-3"><strong>Utgår</strong></div>
                            <div class="col-3"><strong>Kommentar</strong></div>
                            <div class="col-4"></div>
                        </div>
                        <div v-for="key in keys" :key="key.key" class="row border-top p-2">
                            <div class="col-2 pt-2">{{ key.key }}</div>
                            <div class="col-3">
                                <input v-if="editedKey == key.key" class="form-control" type="text" id="expiresInput" v-model="expires"/>
                                <div v-else :class="key.isExpired ? 'text-danger pt-2' : 'text-success pt-2'">
                                    {{ key.expiresText == '' ? 'Aldrig' : key.expiresText }}
                                </div>
                            </div>
                            <div class="col-3">
                                <input v-if="editedKey == key.key" class="form-control" type="text" id="commentInput" v-model="comment"/>
                                <div v-else class="pt-2">{{ key.comment }}</div>
                            </div>
                            <div class="col-4">
                                <div v-if="editedKey == key.key">
                                    <button class="btn btn-link" @click="saveKey">Spara</button>
                                    <button class="btn btn-link" @click="editedKey = null">Avbryt</button>
                                </div>
                                <div v-else>
                                    <button class="btn btn-link" @click="editKey(key.key)">Redigera</button>
                                    <button class="btn btn-link" @click="deleteKey(key.key)">Ta bort</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="error" class="text-danger mt-3">{{ error }}</div>
                    <button class="btn btn-primary mt-3" :disabled="editedKey != null" @click="newKey">Lägg till nyckel</button>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="$emit('close')">Stäng</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import sitesService from '@/services/sitesService'
import helpers from '@/helpers/helpers'

export default {
    props: { siteId: Number },
    emits: [ 'close' ],
    data() {
        return {
            keys: [],
            editedKey: null,
            expires: null,
            comment: null,
            error: null,
        }
    },
    methods: {
        async newKey() {
            const res = await sitesService.createApiKey(this.siteId)
            this.keys.push({
                key: res.data.key,
                expires: null,
                expiresText: '',
                isExpired: false,
                comment: '',
            })
        },
        async saveKey() {
            let date = helpers.parseDateTimeString(this.expires)

            if (date && isNaN(date)) {
                this.error = 'Fel på datum, skriv t ex "2022-02-22 22:22"'
                return
            }

            this.error = ''

            try {
                await sitesService.updateApiKey(this.siteId, this.editedKey, {
                    expires: date,
                    comment: this.comment.trim() === '' ? null : this.comment.trim()
                })

                let key = this.keys.find(k => k.key == this.editedKey)
                if (key) {
                    key.expires = date
                    key.expiresText = date ? helpers.getDateTimeString(date) : '',
                    key.isExpired = date ? date < new Date() : false,
                    key.comment = this.comment.trim()
                }
                this.editedKey = null  
            } catch (err) {
                if (err.response) {
                    this.error = err.response.data
                } else {
                    this.error = err.message
                }
            }
        },
        editKey(key) {
            let keyObject = this.keys.find(k => k.key == key)

            if (keyObject) {
                this.editedKey = key
                this.expires = keyObject.expiresText
                this.comment = keyObject.comment
            }
        },
        deleteKey(key) {
            if (confirm('Ta bort API-nyckel?')) {
                sitesService.deleteApiKey(this.siteId, key)
                const index = this.keys.findIndex(k => k.key == key)
                if (index >= 0) {
                    this.keys.splice(index, 1)
                }
            }
        },
    },
    watch: {
        siteId: {
            async handler() {
                const res = await sitesService.getApiKeys(this.siteId)
                let keys = []
                const now = new Date()
                
                for (const key of res.data) {
                    const expiresDate = key.expires ? new Date(key.expires) : null
                    keys.push({
                        key: key.key,
                        expires: expiresDate ?? '',
                        expiresText: expiresDate ? helpers.getDateTimeString(expiresDate) : '',
                        isExpired: expiresDate ? expiresDate < now : false,
                        comment: key.comment ?? '',
                    })
                }
                this.keys = keys
            }
        }
    },
}
</script>