import seriesHelpers from '@/helpers/seriesHelpers'
import api from './api'

class SitesService {
    getSite(id) {
        return api.get(`/sites/${id}`)
    }

    getSites() {
        return api.get('/sites')
    }

    createSite(site) {
        return api.post('/sites', site)
    }

    updateSite(site) {
        return api.put(`/sites/${site.id}`, site)
    }

    deleteSite(id) {
        return api.delete(`/sites/${id}`)
    }

    getSiteDevices(siteId) {
        return api.get(`/sites/${siteId}/devices`)
    }
    
    uploadFile(id, file) {
        let formData = new FormData()
        formData.append('file', file)

        return api.post(`/sites/${id}/upload`,
            formData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
        )
    }

    getApiKeys(id) {
        return api.get(`/sites/${id}/api-keys`)
    }

    getFirstValidApiKey(id) {
        return api.get(`/sites/${id}/first-valid-api-key`)
    }

    createApiKey(siteId) {
        return api.post(`/sites/${siteId}/api-keys`)
    }

    updateApiKey(siteId, key, data) {
        return api.put(`/sites/${siteId}/api-keys/${key}`, data)
    }

    deleteApiKey(siteId, key) {
        return api.delete(`/sites/${siteId}/api-keys/${key}`)
    }

    async getSiteBillingMeasurements(siteId) {
        const res = await api.get(`/sites/${siteId}/billing_measurements`)
        
        const measurements = []
        for (const measurement of res.data) {
            if (measurement === 'ambiance') {
                continue
            }

            measurements.push({
                value: measurement,
                label: seriesHelpers.getMeasurementLabel(measurement),
                unit: seriesHelpers.getFieldUnit(seriesHelpers.getMeasurementDefaultField(measurement)),
                error: false,
                checked: true,
                price: '',
            })
        }
        
        return measurements
    }

    async getReport(siteId, reportName, params = {}) {
        return api.get(`/sites/${siteId}/report/${reportName}`, { params })
    }
}

export default new SitesService()