import api from './api'

class DocumentsService {
    getDocuments(siteId) {
        return api.get(`/sites/${siteId}/documents`)
    }

    getDocumentUsers(siteId) {
        return api.get(`/sites/${siteId}/document_users`)
    }

    downloadDocument(documentId) {
        return api.get(`/documents/${documentId}`)
    }

    uploadDocument(data) {
        let formData = new FormData()

        formData.append('file', data.file)
        formData.append('category', data.category)
        formData.append('subCategory', data.subCategory)
        formData.append('period', data.period)
        formData.append('sendEmail', data.sendEmail)

        return api.post(`/sites/${data.siteId}/documents`,
            formData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
        )
    }

    deleteDocument(documentId) {
        return api.delete(`/documents/${documentId}`)
    }
}

export default new DocumentsService()