<template>
    <div class="modal" id="apartmentModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ data.id ? 'Redigera mätplats' : 'Ny mätplats' }}</h4>
                    <button type="button" class="btn-close" aria-label="Close" @click="$emit('close')"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="mb-3">
                            <label for="formNumber" class="form-label">Nummer <i class="bi bi-info-circle" title="Nummer som används vid avräkning. (Behövs)"></i></label>
                            <input class="form-control" type="text" id="formNumber" v-model="data.number">
                            <div class="text-danger" v-show="missingNumber">Vänligen ange nummer</div>
                        </div>
                        <div class="mb-3">
                            <label for="formAddress" class="form-label">Adress <i class="bi bi-info-circle" title="Mätplatsens adress. (Frivillig)"></i></label>
                            <input class="form-control" type="text" id="formAddress" v-model="data.address">
                        </div>
                        <div class="mb-3">
                            <label for="typeSelect" class="form-label">Typ <i class="bi bi-info-circle" title="Bestämmer till vad mätdatan skall användas. Vanligen gäller det översta alternativet. (Behövs)"></i></label>
                            <select id="typeSelect" class="form-select" v-model="data.type">
                                <option value="1">Bostad, affärslokal, el. dyl.</option>
                                <option value="2">Undercentral</option>
                                <option value="3">Gemensamt utrymme</option>
                                <option value="99">Annan</option>
                            </select>
                            <div class="text-danger" v-show="missingType">Vänligen välj en typ</div>
                        </div>
                    </form>
                    <div v-show="errorMessage" class="text-danger mt-3 mb-3">
                        {{ errorMessage }}
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="$emit('close')">Avbryt</button>
                    <button type="button" class="btn btn-primary" @click="submit">Spara</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import apartmentsService from '@/services/apartmentsService'

export default {
    props: {
        data: Object
    },
    emits: [ 'submit', 'close' ],
    data() {
        return {
            missingNumber: false,
            missingType: false,
            errorMessage: null,
        }
    },
    methods: {
        async submit() {
            let valid = true

            if (!this.data.number) {
                this.missingNumber = true
                valid = false
            }

            if (!this.data.type) {
                this.missingType = true
                valid = false
            }

            if (!valid) {
                return
            }

            try {
                const apartment = {
                    site_id: this.data.siteId,
                    number: this.data.number,
                    address: this.data.address,
                    type: this.data.type,
                }

                if (this.data.id) {
                    await apartmentsService.updateApartment(this.data.id, apartment)
                } else {
                    const res = await apartmentsService.createApartment(apartment)
                    this.data.id = res.data.id
                }

                this.$emit('submit')
                this.$emit('close')
            } catch (err) {
                if (err.response) {
                    if (err.response.status === 400) {
                        if (err.response.data === 'number taken') {
                            this.errorMessage = 'Numret används redan på en annan mätplas'
                        }
                    } else {
                        this.errorMessage = 'Server: ' + err.response.data
                    }                    
                } else {
                    console.log(err)
                    this.errorMessage = err.message
                }
            }
        },
    },
    watch: {
        data: {
            handler() {
                this.missingNumber = false
                this.errorMessage = null
            }
        }
    },
}
</script>