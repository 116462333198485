import { ref } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import tokenService from '../services/tokenService'
import Home from '../views/Home'
import Login from '../views/Login'
import Sites from '../views/Sites'
import NetworkError from '../views/NetworkError'
import Apartment from '../views/Apartment'
import Users from '../views/Users'
import FileNotFound from '../views/FileNotFound'
import Site from '../views/Site'
import Demo from '../views/Demo'
import FortnoxActivation from '../views/FortnoxActivation'

const intendedRoute = ref(null)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: { requiresAuth: true },
    },
    {
        path: '/sites',
        name: 'sites',
        component: Sites,
        meta: { requiresAuth: true },
    },
    {
        path: '/site/:id',
        name: 'site',
        component: Site,
        meta: { requiresAuth: true },
    },
    {
        path: '/apartment/:id',
        name: 'apartment',
        component: Apartment,
        meta: { requiresAuth: true },
    },
    {
        path: '/users',
        name: 'users',
        component: Users,
        meta: { requiresAuth: true },
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/demo',
        name: 'demo',
        component: Demo,
        meta: { requiresAuth: true },
    },
    {
        path: '/network_error',
        name: 'network_error',
        component: NetworkError,
    },
    {
        path: '/404',
        name: '404',
        component: FileNotFound,
    },
    {
        path: '/activate',
        name: 'activate',
        component: FortnoxActivation,
        meta: { requiresAuth: true },
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.path === '/ombokning' || to.path === '/ombokning/') {
        window.location.href = 'https://ombokning.oneviewer.se'
    }
    if (to.meta.requiresAuth && !isAuthenticated()) {
        // Save the intended route before redirecting to login
        console.log('saving route')
        intendedRoute.value = to
        next('/login')
    } else {
        next()
    }
})

export function getIntendedRoute() {
    return intendedRoute.value
}

export function clearIntendedRoute() {
    intendedRoute.value = null
}

function isAuthenticated() {
    const user = tokenService.getUser()
    return user != null
}

export default router
