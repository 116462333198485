import api from './api'

class CustomersService {
    getCustomers() {
        return api.get('/customers')
    }

    createCustomer(customer) {
        return api.post('/customers', customer)
    }

    updateCustomer(customer) {
        return api.put(`/customers/${customer.id}`, customer)
    }

    deleteCustomer(id) {
        return api.delete(`/customers/${id}`)
    }
}

export default new CustomersService()