import api from './api'

class UsersService {
    getUsers(params) {
        return api.get('/users', { params })
    }

    createUser(form) {
        return api.post('/users', form)
    }

    updateUser(form) {
        return api.put(`/users/${form.id}`, form)
    }

    deleteUser(id) {
        return api.delete(`/users/${id}`)
    }

    getUserCustomers(id) {
        return api.get(`/users/${id}/customers`)
    }

    createApartmentLogins(siteId) {
        return api.post(`/sites/${siteId}/apartment_logins`)
    }
}

export default new UsersService()