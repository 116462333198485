import api from './api'
import tokenService from './tokenService'

export default () => {
    api.interceptors.request.use(
        async (config) => {
            let accessToken = tokenService.getLocalAccessToken()

            if (!config.url.startsWith('/web/')) {
                config.url = '/web' + config.url
            }

            if (!config.url.startsWith('/web/auth/') && tokenService.tokenExpires()) {
                try {
                    const res = await api.post("/web/auth/refreshtoken", {
                        refreshToken: tokenService.getLocalRefreshToken()
                    })

                    accessToken = res.data.accessToken
                    tokenService.updateLocalAccessToken(accessToken, res.data.expires)
                } catch (error) {
                    return Promise.reject(error)
                }
            }

            if (accessToken) {
                config.headers["x-access-token"] = accessToken
            }
            
            return config
        },
        (error) => {
            return Promise.reject(error)
        }
    )

    api.interceptors.response.use(
        (res) => {
            return res
        },
        async (err) => {
            const config = err.config

            if (err.code === 'ERR_NETWORK') {
                window.location.href = '/network_error?url=' + window.location.pathname
                return
            }
            
            if (err.response && err.response.status === 403) {
                window.location.href = '/login?url=' + window.location.pathname
            }
            
            if (err.response && err.response.status === 404) {
                window.location.href = '/404'
            }

            if (config.url !== "/web/auth/login" && err.response) {
                // Access Token was expired
                if (err.response.status === 401 && !config._retry) {
                    config._retry = true

                    try {
                        const res = await api.post("/web/auth/refreshtoken", {
                            refreshToken: tokenService.getLocalRefreshToken()
                        })

                        tokenService.updateLocalAccessToken(res.data.accessToken, res.data.expires)

                        return api(config)
                    } catch (error) {
                        return Promise.reject(error)
                    }
                }
            }

            return Promise.reject(err)
        }
    )
}