<template>
    <div class="container text-center">
        <div style="font-size: 200px;">
            <i class="bi bi-question-square"></i>
        </div>
        <h2>Sidan hittades inte.</h2>
        <button class="btn btn-primary mt-5" @click="goToStart">Gå till start</button>
    </div>
</template>

<script>
import tokenService from '@/services/tokenService'

export default {
    methods: {
        goToStart() {
            const user = tokenService.getUser()
            
            if (user.level == 30) {
                window.location.href = `/site/${user.siteId}`
            } else if (user.level == 40) {
                window.location.href = `/apartment/${user.apartmentId}`
            } else if (user.level == 5) {
                window.location.href = `/demo`
            } else if (this.url) {
                window.location.href = this.$route.params.url
            } else {
                window.location.href = '/sites'
            }
        },
    }
};
</script>