<template></template>

<script setup>
import { useRouter } from 'vue-router'
import tokenService from '@/services/tokenService'

const router = useRouter()

const user = tokenService.getUser()

if (!user) {
    router.push('/login')
} else if (user.level == 30) {
    router.push(`/site/${user.siteId}`)
} else if (user.level == 40) {
    router.push(`/apartment/${user.apartmentId}`)
} else {
    router.push('/sites')
}
</script>