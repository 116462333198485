<template>
    <div>
        <div class="text-center">
            <img src="@/assets/background.png" />
            <div style="position: relative; bottom: 900px;">
                <div style="font-size:72px;" title="Tidszon: Europa / Stockholm">
                    {{ time }}
                </div>            
                <div style="font-size:24px;">
                    {{ date }}
                </div>            
            </div>
        </div>
        <div style="position: absolute; top: 300px; left: 135px" class="inc-chart">
            <Bar :data="chartData.water" :options="chartOptions" />
        </div>
        <div style="position: absolute; top: 550px; left: 135px" class="inc-chart">
            <Bar :data="chartData.energy" :options="chartOptions" />
        </div>
        <div style="position: absolute; top: 300px; left: 1385px" class="inc-chart">
            <Bar :data="chartData.solar" :options="chartOptions" />
        </div>
        <div style="position: absolute; top: 550px; left: 1385px" class="inc-chart">
            <Bar :data="chartData.mains" :options="chartOptions" />
        </div>
        <div style="position: absolute; top: 400px; left: 660px" class="small-chart">
            <Bar :data="chartData.combo" :options="chartOptions" />
        </div>
        <div style="position: absolute; top: 400px; left: 970px" class="small-chart">
            <Bar :data="chartData.heating" :options="chartOptions" />
        </div>
        <div style="position: absolute; top: 650px; left: 660px" class="small-chart">
            <Bar :data="chartData.electricity" :options="chartOptions" />
        </div>
        <div style="position: absolute; top: 650px; left: 970px" class="small-chart">
            <Bar :data="chartData.charging" :options="chartOptions" />
        </div>
        <div style="position: absolute; top: 65px; left: 90px" class="text-center">
            <img src="@/assets/overlay.png" />
        </div>
        <div style="position: absolute; top: 150px; left: 200px">
            <div style="font-size:36px;">
                <i class="bi bi-check-circle-fill" style="color: #198754;"></i>
                Inga larm
            </div>            
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, onMounted, onBeforeUnmount } from 'vue'
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js'
import demoService from '@/services/demoService'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement)

let time = ref(null)
let date = ref(null)
const chartData = reactive({
    electricity: { labels: [], datasets:[{data:[]}]},
    heating: { labels: [], datasets:[{data:[]}]},
    charging: { labels: [], datasets:[{data:[]}]},
    mains: { labels: [], datasets:[{data:[]}]},
    energy: { labels: [], datasets:[{data:[]}]},
    solar: { labels: [], datasets:[{data:[]}]},
    combo: { labels: [], datasets:[{data:[]}]},
    water: { labels: [], datasets:[{data:[]}]},
})

const chartOptions = reactive({
    locale: 'sv-SE',
    animation: {
        duration: 0
    },
    scales: {
        y: { beginAtZero: true }
    },
})

let clockTimer = ref(null)

function onClockTimer() {
    time.value = new Date().toLocaleTimeString('sv', { hour: '2-digit', minute:'2-digit' })
    date.value = new Date().toLocaleDateString('sv', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
}

function isToday(date) {
    const now = new Date()
    return date.getFullYear() == now.getFullYear() && date.getMonth() == now.getMonth() && date.getDate() == now.getDate()
}

function isYesterday(date) {
    const now = new Date()
    return date.getFullYear() == now.getFullYear() && date.getMonth() == now.getMonth() && date.getDate() == (now.getDate() - 1)
}

function getColor(label) {
    switch (label) {
        case 'electricity':
            return '#28c641'
        case 'mains':
            return '#28c641'
        case 'charging':
            return '#68f681'
        case 'hot_water':
        case 'heating':
            return '#ee2200'
        case 'energy':
            return '#ffaa00'
        case 'solar':
            return '#ffe000'
        case 'water':
        case 'cold_water':
            return '#2d93dd'
    }

}

function getLabel(label) {
    switch (label) {
        case 'charging':
            return 'Laddning (kWh)'
        case 'electricity':
            return 'Förbruknings-el (kWh)'
        case 'heating':
            return 'Uppvärmning (kWh)'
        case 'water':
            return 'Vatten (m³)'
        case 'hot_water':
            return 'Varm (m³)'
        case 'cold_water':
            return 'Kall (m³)'
        case 'energy':
            return 'Fjärrvärme (kWh)'
        case 'solar':
            return 'Sol-energi (kWh)'
        case 'mains':
            return 'Elektricitet (kWh)'
    }
}

async function onUpdateCharts() {
    const data = (await demoService.getStatistics('electricity')).data


    let waterLabels = null
    let cwDataset = {
        label: getLabel('cold_water'),
        backgroundColor: getColor('cold_water'),
        data: [],
    }
    let hwDataset = {
        label: getLabel('hot_water'),
        backgroundColor: getColor('hot_water'),
        data: [],
    }

    for (const label in data) {
        let labels = []
        let dataset = {
            label: getLabel(label),
            backgroundColor: getColor(label),
            data: [],
        }
                                
        for (const timestamp in data[label]) {
            const date = new Date(timestamp)
            if (isToday(date)) {
                labels.push('idag')
            } else if (isYesterday(date)) {
                labels.push('igår')
            } else {
                labels.push(date.getDate() + '/' + (date.getMonth() + 1))
            }

            if (label == 'cold_water') {
                cwDataset.data.push(data[label][timestamp])
            } else if (label == 'hot_water') {
                hwDataset.data.push(data[label][timestamp])
            } else if (data[label][timestamp] !== null) {
                dataset.data.push(data[label][timestamp])
            } else {
                dataset.data.push(null)
            }
        }

        if (label != 'cold_water' && label != 'hot_water') {
            chartData[label] = { labels, datasets: [ dataset ] }
            if (!waterLabels) {
                waterLabels = labels
            }
        }
    }

    chartData['combo'] = { labels: waterLabels, datasets: [ cwDataset, hwDataset ] }
}

onMounted(() => {
    onClockTimer()
    clockTimer.value = setInterval(onClockTimer, 1000)
    onUpdateCharts()
})

onBeforeUnmount(() => {
    clearInterval(clockTimer.value)
})

</script>

<style>
.inc-chart {
    width: 400px;
    height: 200px;
    background: white;
    padding: 5px;
    border-radius: 10px;
    box-shadow: 5px 5px 5px #00000020;
}
.small-chart {
    width: 285px;
    height: 180px;
    background: white;
    padding: 5px;
    border-radius: 10px;
    box-shadow: 5px 5px 5px #00000020;
}
</style>