<template>
    <div class="container mt-3" style="text-align: center;">
        <div class="months-row">
            <div class="month-col" v-for="md in monthData" :key="md.series">
                <month-usage 
                    :series="md.series"
                    :this-month="md.thisMonth"
                    :last-month="md.lastMonth"
                    :this-month-avg="md.thisMonthAvg"
                    :last-month-avg="md.lastMonthAvg"
                    :chart-data="md.chartData"
                />
            </div>
        </div>
    </div>
</template>

<script>
import readingsService from '@/services/readingsService'
import MonthUsage from '@/components/MonthUsage.vue'
import seriesHelpers from '@/helpers/seriesHelpers'

export default {
    components: { MonthUsage },
    data() {
        return {
            apartmentId: 0,
            monthData: [],
        }
    },
    async mounted() {
        const res = await readingsService.getApartmentStatistics(this.$route.params.id)

        let data = []
        for (const series in res) {
            data.push({
                series,
                lastMonth: res[series].lastMonth,
                thisMonth: res[series].thisMonth,
                lastMonthAvg: res[series].lastMonthAvg,
                thisMonthAvg: res[series].thisMonthAvg,
                chartData: {
                    labels: [ 'må', 'ti', 'on', 'to', 'fr', 'lö', 'sö' ],
                    datasets: [{
                        label: 'förra veckan',
                        backgroundColor: seriesHelpers.getColor(series) + '70',
                        data: res[series].lastWeek
                    }, {
                        label: 'denna vecka',
                        backgroundColor: seriesHelpers.getColor(series),
                        data: res[series].thisWeek
                    }]

                },
            })
        }

        this.monthData = data
    },
}
</script>

<style>
.months-row {
    display: inline-block;
    text-align: center;
}
.month-col {
    width: 300px;
    float: left;
    margin-left: 1em;
    margin-right: 1em;
} 
</style>