<template>
    <div class="container">
        <div class="row mt-3">
            <div class="col">
                <h2>{{ data.name }}</h2>
            </div>
            <div class="col" v-if="data.user.level <= 10">
                <div class="float-top float-end">
                    <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" type="button" id="dropdownMenuButton">
                            Åtgärder
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                            <li v-if="data.user.level <= 10">
                                <button class="btn btn-link dropdown-item" @click="showImportReadingsModal">
                                    Importera avläsningar
                                </button>
                            </li>
                            <li v-if="data.user.level === 1">
                                <button class="btn btn-link dropdown-item" @click="showApartmentModal(null)">
                                    Lägg till mätplats
                                </button>
                            </li>
                            <li v-if="data.user.level === 1">
                                <button class="btn btn-link dropdown-item" @click="showImportModal">
                                    Importera mätarlista
                                </button>
                            </li>
                            <li v-if="data.user.level === 1">
                                <button class="btn btn-link dropdown-item" @click="exportToFile">
                                    Exportera mätarlista
                                </button>
                            </li>
                            <li v-if="data.user.level === 1">
                                <button class="btn btn-link dropdown-item" @click="apiKeysModal.show()">
                                    API-nycklar
                                </button>
                            </li>
                            <li v-if="data.user.level === 1">
                                <button class="btn btn-link dropdown-item" @click="showUploadDocumentModal">
                                    Ladda upp dokument
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <button id="dashboard" class="btn btn-link nav-link active" @click="goToTab('dashboard')">Översikt</button>
            </li>
            <li class="nav-item">
                <button id="devices" class="btn btn-link nav-link" @click="goToTab('devices')">Mätare</button>
            </li>
            <li class="nav-item">
                <button id="statistics" class="btn btn-link nav-link" @click="goToTab('statistics')">Statistik</button>
            </li>
            <li class="nav-item" v-show="data.user.level <= 10 || data.user.hasReports">
                <button id="reports" class="btn btn-link nav-link" @click="goToTab('reports')">Rapporter</button>
            </li>
            <li class="nav-item">
                <button id="billing" class="btn btn-link nav-link" @click="goToTab('billing')">Avräkning</button>
            </li>
            <li class="nav-item">
                <button id="control-reports" class="btn btn-link nav-link" @click="goToTab('control-reports')">Kontrollrapporter</button>
            </li>
            <li class="nav-item">
                <button id="certificates" class="btn btn-link nav-link" @click="goToTab('certificates')">Kalibreringsintyg</button>
            </li>
            <!-- <li class="nav-item">
                <button id="video" class="btn btn-link nav-link" @click="goToTab('video')">Video</button>
            </li> -->
            <li class="nav-item">
                <button id="imports" class="btn btn-link nav-link" @click="goToTab('imports')">Importlogg</button>
            </li>
        </ul>
        <div>
            <SiteOverview v-if="data.tab === 'dashboard'" :config="data.reportingStatus" />
            <Devices v-if="data.tab === 'devices'" :apartments="data.apartments" @reload="reload" 
                @show-statistics="onShowStatistics" @show-device-readings="onShowDeviceReadings"
            />
            <Statistics v-if="data.tab === 'statistics'" :form="statisticsData" :apartments-data="data.apartments" />
            <Reports v-if="data.tab === 'reports'" />
            <Billing v-if="data.tab === 'billing'" />
            <!-- <Video v-if="data.tab === 'video'" /> -->
            <Imports v-if="data.tab === 'imports'" :site-id="importLogData.siteId" :start="importLogData.start" :stop="importLogData.stop" />
            <Documents v-if="data.tab === 'control-reports'" :data="reportsData" @reload="loadDocuments" />
            <Documents v-if="data.tab === 'certificates'" :data="certificatesData" @reload="loadDocuments" />
        </div>
        <ApiKeysModal id="apiKeysModal" :site-id="data.siteId" @close="apiKeysModal.hide()" />
        <ImportDevices id="importModal" :data="importFileData" @close="importModal.hide()" @submit="resultModal.show()" @reload="reload" />
        <ImportResults id="resultModal" :data="importFileData" @close="resultModal.hide()" />
        <ApartmentForm id="apartmentModal" :data="apartmentData" @close="apartmentModal.hide()" @submit="reload" />
        <ReadingsModal id="readingsModal" ref="readingsModalRef" :data="readingsData" :apartments="data.apartments" 
            @close="readingsModal.hide()" @update="onReadingsUpdate" @change-apartment="onReadingsChangeApartment" />
        <ImportReadingsModal id="importReadingsModal" :data="importReadingsData" @close="importReadingsModal.hide()" @reload="reload" />
        <UploadDocumentModal id="uploadDocumentModal" :data="uploadDocumentData" @close="uploadDocumentModal.hide()" @reload="loadDocuments" />
    </div>
</template>

<script setup>
import { ref, reactive, onMounted, defineAsyncComponent } from 'vue'
import { useRoute } from 'vue-router'
import { Modal } from 'bootstrap'
// Serives
import sitesService from '@/services/sitesService'
import tokenService from '@/services/tokenService'
import readingsService from '@/services/readingsService'
import documentsService from '@/services/documentsService'
// Components
import ApartmentForm from '@/components/ApartmentModal.vue'
import ImportDevices from '@/components/ImportDevicesModal.vue'
import ImportResults from '@/components/ImportResultsModal.vue'
import ApiKeysModal from '@/components/ApiKeysModal.vue'
import Reports from '@/components/Reports.vue'
import Statistics from '@/components/Statistics.vue'
import SiteOverview from '@/components/SiteOverview.vue'
import ReadingsModal from '@/components/ReadingsModal.vue'
import ImportReadingsModal from '@/components/ImportReadingsModal.vue'
import Documents from '@/components/Documents.vue'
import UploadDocumentModal from '@/components/UploadDocument.vue'
// Helpers
import devicesHelpers from '@/helpers/devicesHelpers'
import helpers from '@/helpers/helpers'
import seriesHelpers from '@/helpers/seriesHelpers'
import dateTime from '@/helpers/dateTime'

const Devices = defineAsyncComponent(
    () => import('../components/Devices.vue')
)

const Billing = defineAsyncComponent(
    () => import('../components/Billing.vue')
)

const Video = defineAsyncComponent(
    () => import('../components/Video.vue')
)

const Imports = defineAsyncComponent(
    () => import('../components/ImportLog.vue')
)

const route = useRoute()

let apiKeysModal = ref(null)
let importModal = ref(null)
let resultModal = ref(null)
let apartmentModal = ref(null)
let readingsModal = ref(null)
let importReadingsModal = ref(null)
let uploadDocumentModal = ref(null)
const readingsModalRef = ref(null)

const data = reactive({
    siteId: null, 
    name: null,
    properties: {},
    tab: 'dashboard', // Default active tab in template
    user: {},
    apartments: [],
    reportingStatus: [],
    hasReports: false,
    hasCertificates: false,
    documents: [],
})

const importFileData = reactive({})
const apartmentData = reactive({})
const importLogData = reactive({})
const readingsData = reactive({
    apartmentId: null,
    deviceId: null,
    start: null,
    stop: null,
    devices: [],
    readings: [],
    hasMore: false,
    fields: [],
})
const reportsData = reactive({ documents: [], period: 'all', type: 'all', types: [] })
const certificatesData = reactive({ documents: [], period: 'all', type: 'all', types: [] })

readingsData.start = dateTime.floor(new Date(), 'day')
readingsData.stop = dateTime.ceil(new Date(), 'day')

const statisticsData = reactive({
    site: null,
    apartment: null,
    mp: null,
    field: null,
    period: null,
    date: null,
    year: null,
    month: null,
    week: null,
    periods: null,
})

const allPeriods = [
    { minutes: 15, value: '15minsDay', label: '15 min. / dygn' },
    { minutes: 60, value: 'hoursDay', label: 'timmar / dygn' },
    { minutes: 60, value: 'hoursWeek', label: 'timmar / vecka' },
    { minutes: 24 * 60 * 7, value: 'daysWeek', label: 'dagar / vecka' },
    { minutes: 24 * 60, value: 'daysMonth', label: 'dagar / månad' },
    { minutes: 24 * 60 * 30, value: 'monthsYear', label: 'månader / år' },
]

const importReadingsData = reactive({
    enableAdvanced: false,
    showAdvanced: false,
    columns: '',
    errorMessage: null,    
    successMessages: null,
})

const uploadDocumentData = reactive({
    siteId: null, 
    emailAddresses: null,
    period: null,
    category: null,
    subCategory: null,
    missingPeriod: false,
    missingCategory: false,
    missingSubCategory: false,
    sendEmail: true,
    noFileSelected: false,
    errorMessage: null,    
    successMessages: null,
})

function showImportReadingsModal() {
    importReadingsData.enableAdvanced = data.user.level === 1
    importReadingsData.showAdvanced = false
    importReadingsData.columns = ''
    importReadingsData.errorMessage = null
    importReadingsData.successMessages = null
    importReadingsModal.show()
}

async function showUploadDocumentModal() {
    uploadDocumentData.siteId = data.siteId
    uploadDocumentData.period = null
    uploadDocumentData.category = null
    uploadDocumentData.subCategory = null
    uploadDocumentData.sendEmail = false
    uploadDocumentData.missingPeriod = false
    uploadDocumentData.missingCategory = false
    uploadDocumentData.missingSubCategory = false
    uploadDocumentData.noFileSelected = false
    uploadDocumentData.errorMessage = null
    uploadDocumentData.successMessages = null

    const users = (await documentsService.getDocumentUsers(data.siteId)).data
    const recipients = []
    for (const u of users) {
        if (u.email) {
            recipients.push(`${u.name} (${u.email})`)
        }
    }
    uploadDocumentData.emailAddresses = recipients.join(', ')

    uploadDocumentModal.show()    
}

function showImportModal() {
    importFileData.siteId = data.siteId
    importFileData.errors = []
    importFileData.changes = []
    importModal.show()
}

function goToTab(tab) {
    let oldTab = document.getElementById(data.tab)
    oldTab.classList.remove('active')
    let newTab = document.getElementById(tab)
    newTab.classList.add('active')
    data.tab = tab
}

function onShowStatistics(args) {
    const apartment = data.apartments.find(a => a.id == args.apartment)
    if (!apartment) {
        return
    }
    
    const mp = apartment.devices.find(d => d.measuringPointId == args.mp)
    if (!mp) {
        return
    }

    statisticsData.apartment = args.apartment
    statisticsData.mp = args.mp
    statisticsData.field = seriesHelpers.getMeasurementDefaultField(mp.measurement)
    goToTab('statistics')
}

function showApartmentModal(apartmentId) {
    if (apartmentId) {
        const apartment = data.apartments.find(a => a.id == apartmentId)
        apartmentData.siteId = data.siteId
        apartmentData.id = apartment.id
        apartmentData.number = apartment.number
        apartmentData.address = apartment.address
        apartmentData.type = apartment.type
    } else {
        apartmentData.siteId = data.siteId
        apartmentData.id = null
        apartmentData.number = null
        apartmentData.address = null
        apartmentData.type = null
    }
    apartmentModal.show()
}

async function reload() {
    const result = await devicesHelpers.loadDevices(data.siteId)
    data.apartments = result.apartments
    data.reportingStatus = result.statusConfig

    let minFreqMinutes = 60 * 24
    switch (data.properties.max_stats_resolution ?? 'hour') {
        case '15min':
            minFreqMinutes = 15
            break
        case 'hour':
            minFreqMinutes = 60
            break
        case 'day':
            minFreqMinutes = 60 * 24
            break
        case 'week':
            minFreqMinutes = 60 * 24 * 7
            break
        case 'month':
            minFreqMinutes = 60 * 24 * 30
            break
        case 'year':
            minFreqMinutes = 60 * 24 * 365
            break
    }
    const periods = []
    for (const period of allPeriods) {
        if (period.minutes >= minFreqMinutes) {
            periods.push(period)
        }
    }
    statisticsData.periods = periods
}

async function exportToFile() {
    const bom = '\uFEFF'
    const file = devicesHelpers.getDevicesCsvFile(data.apartments)
    const blob = new Blob([bom + file], { type: 'text/csv' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = `Mätare ${data.name} ${helpers.getDateTimeString(new Date())}.csv`
    link.click()
    URL.revokeObjectURL(link.href)
}

function onShowDeviceReadings(args) {
    readingsData.apartmentId = args.apartmentId
    onReadingsChangeApartment(args.deviceId)
    readingsModal.show()
}

function onReadingsChangeApartment(deviceId = null) {
    const apartment = data.apartments.find(a => a.id == readingsData.apartmentId)
    if (apartment) {
        readingsData.devices = apartment.devices
        if (apartment.devices.length === 1) {
            readingsData.deviceId = apartment.devices[0].deviceId
            onReadingsUpdate()
        } else if (deviceId) {
            readingsData.deviceId = deviceId
            onReadingsUpdate()
        }
    }
}

async function onReadingsUpdate() {
    if (!readingsData.deviceId) {
        return
    }

    const data = (await readingsService.getDeviceReadings(
        readingsData.deviceId, 
        readingsData.start, 
        readingsData.stop
    )).data
    
    const array = []
    for (const timestamp in data) {
        array.push({
            timestamp,
            fields: data[timestamp],
        })
    }
    array.sort((a, b) => a.timestamp < b.timestamp ? 1 : -1)

    const fields = []
    if (array.length > 0) {
        for (const field in array[0].fields) {
            fields.push(field)
        }
    }

    readingsData.readings = array
    readingsData.fields = fields
    readingsData.hasMore = array.length >= 100
}

async function loadDocuments() {
    data.documents = (await documentsService.getDocuments(data.siteId)).data

    reportsData.types = [
        'Kallvatten',
        'Varmvatten',
        'Temperaturgivare',
        'Integreringsverk',
    ]
    certificatesData.types =[
        'Massflöde',
        'Volym',
        'Kallvatten',
        'Varmvatten',
    ]

    filterDocuments()
}

async function filterDocuments() {
    let hasReports = false
    let hasCertificates = false

    reportsData.documents = []
    certificatesData.documents = []

    for (const doc of data.documents) {
        const downloadLines = []
        doc.downloads.forEach(dl => downloadLines.push(`${new Date(dl.timestamp).toLocaleString('sv')} ${dl.name} (${dl.email})`))

        const type = doc.tags && doc.tags.length > 1 ? doc.tags[1] : ''

        const row = {
            id: doc.id,
            filename: doc.filename,
            created: doc.created,
            period: doc.period,
            type,
            isDownloaded: doc.isDownloaded,
            downloadsCount: doc.downloads.length,
            downloadsTitle: downloadLines.join('\n')
        }

        if (doc.tags.includes('Kontroll ISO/IEC 17020:2012')) {
            if ((reportsData.period === 'all' || reportsData.period === doc.period) &&
                (reportsData.type === 'all' || doc.tags.includes(reportsData.type))
            ) {
                reportsData.documents.push(row)
            }
            hasReports = true
        } else if (doc.tags.includes('Kalibrering ISO/IEC 17025:2018')) {
            if ((certificatesData.period === 'all' || certificatesData.period === doc.period) &&
                (certificatesData.type === 'all' || doc.tags.includes(certificatesData.type))
            ) {
                certificatesData.documents.push(row)
            }
            hasCertificates = true
        }
    }
    data.hasReports = hasReports
    data.hasCertificates = hasCertificates
}

onMounted(async () => {
    const res = await sitesService.getSite(route.params.id)

    // Initiate data
    data.siteId = res.data.id
    data.name = res.data.name
    data.properties = res.data.properties ?? {}
    data.user = tokenService.getUser()
    loadDocuments()

    // Get references to modals
    apiKeysModal = new Modal(document.getElementById('apiKeysModal'))
    importModal = new Modal(document.getElementById('importModal'))
    resultModal = new Modal(document.getElementById('resultModal'))
    apartmentModal = new Modal(document.getElementById('apartmentModal'))
    readingsModal = new Modal(document.getElementById('readingsModal'))
    importReadingsModal = new Modal(document.getElementById('importReadingsModal'))
    uploadDocumentModal = new Modal(document.getElementById('uploadDocumentModal'))

    // Initiate statistics tab form
    let now = new Date()
    statisticsData.site = res.data.id
    statisticsData.date = now.toISOString().substring(0, 10)
    statisticsData.year = now.getFullYear().toString()
    statisticsData.month = now.getMonth().toString()
    statisticsData.week = dateTime.getWeekNumber(now).toString()
    statisticsData.period = 'daysMonth'

    // Initate import log tab
    importLogData.siteId = res.data.id
    importLogData.start = dateTime.floor(new Date(), 'day')
    importLogData.stop = dateTime.add(importLogData.start, 'day', 1)
    
    reload()
})
</script>