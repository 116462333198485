<template>
    <div class="container status-panel pb-5">
        <div class="container">
            <div class="status-item" v-if="config.statusChartData">
                <div class="mb-2">RAPPORTERING</div>
                <div>
                    <div class="report-col">
                        <div class="status-report">
                            <doughnut id="statusChart" :data="config.statusChartData" :options="statusChartOptions" />
                        </div>
                        <div style="position: relative; bottom: 100px; font-size: x-large;" title="Nyare än 48 h (inkl. walk-by)">
                            {{ isNaN(config.percentage) ? '-' : `${config.percentage}%` }}
                        </div>
                    </div>
                    <div class="report-col" style="text-align: left">
                        <table class="mt-3">
                            <tbody>
                                <tr>
                                    <td><span class="ok">&nbsp;</span></td>
                                    <td>
                                        &nbsp;{{ config.status[0] }}
                                        <span class="status-label text-muted">nyare än 48 h</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td><span class="never">&nbsp;</span></td>
                                    <td>
                                        &nbsp;{{ config.status[1] }}
                                        <span class="status-label text-muted">aldrig inkomna</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td><span class="late">&nbsp;</span></td>
                                    <td>
                                        &nbsp;{{ config.status[2] }}
                                        <span class="status-label text-muted">äldre än 48 h</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td><span class="walkby">&nbsp;</span></td>
                                    <td>
                                        &nbsp;{{ config.status[3] }}
                                        <span class="status-label text-muted">walk-by</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="mt-2">
                            <div>Senaste importen:</div>
                            <div>{{ latestText }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="status-item" v-for="chart in barCharts">
                <div>{{ chart.title }}</div>
                <bar :data="chart.data" :options="chartOptions" />
            </div>
        </div>
    </div>
</template>

<script>
import readingsService from '@/services/readingsService'
import { Chart as ChartJS, Title, ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js'
import { Bar, Doughnut } from 'vue-chartjs'
import seriesHelpers from '@/helpers/seriesHelpers'
import importLogService from '@/services/importLogService'
import dateTime from '@/helpers/dateTime'

ChartJS.register(Title, Tooltip, Legend, ArcElement, BarElement, CategoryScale, LinearScale, PointElement, LineElement)

export default  {
    components: { Bar, Doughnut },
    props: { 
        config: Object,
    },
    data() {
        return {
            statusChartOptions: {
                locale: 'sv-SE',
                animation: {
                    duration: 0
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false
                    },
                },
                maintainAspectRatio: true,
                elements: {
                    arc: {
                        borderWidth: 1,
                    },
                },
            },
            chartOptions: {
                locale: 'sv-SE',
                animation: {
                    duration: 0
                },
                scales: {
                    x: {
                        border: {
                            display: false,
                        },
                        grid: {
                            display: false,
                        },
                    },
                },
                maintainAspectRatio: false,
            },
            barCharts: [],
            latestText: '',
        }
    },
    methods: {
        async updateLatestImport() {
            const latest = await importLogService.getSiteLatestImport(this.$route.params.id)
            if (latest) {
                this.latestText = dateTime.durationText(new Date(latest), new Date())
            } else {
                this.latestText = 'Mer än ett år sedan.'
            }
        },
    },
    async mounted() {
        if (this.$route.params.id) {
            const now = new Date().toISOString()
            const res = (await readingsService.getSiteStatusStatistics(this.$route.params.id, now)).data

            this.updateLatestImport()

            let charts = []

            for (const series in res) {
                charts.push({
                    title: seriesHelpers.getLabel(series, { upperCase: true }),
                    data: {
                        labels: [ 'mån', 'tis', 'ons', 'tor', 'fre', 'lör', 'sön' ],
                        datasets: [{
                            label: 'förra veckan',
                            backgroundColor: seriesHelpers.getColor(series) + '80',
                            data: res[series].lastWeek
                        }, {
                            label: 'denna vecka',
                            backgroundColor: seriesHelpers.getColor(series),
                            data: res[series].thisWeek
                        }]
                    },
                })
            }

            this.barCharts = charts
        }
    },
}
</script>

<style>
.report-col {
    float: left;
    margin-left: 2em;
}
.status-panel {
    display: inline-block;
    background-color: #fff;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}
.status-report {
    display: inline-block;
    height: 150px;
    margin-top: 1em;
}
.status-item {
    height: 200px;
    width: 450px;
    text-align: center;
    float: left;
    margin-top: 2em;
    margin-left: 1em;
}
.ok {
    width: 1em;
    height: 1em;
    border-radius: 0.5em;
    display: inline-block;
    background-color: #198754;
    vertical-align: -3px;
}
.never {
    width: 1em;
    height: 1em;
    border-radius: 0.5em;
    display: inline-block;
    background-color: #ffc107;
    vertical-align: -3px;
}
.late {
    width: 1em;
    height: 1em;
    border-radius: 0.5em;
    display: inline-block;
    background-color: #dc3545;
    vertical-align: -3px;
}
.walkby {
    width: 1em;
    height: 1em;
    border-radius: 0.5em;
    display: inline-block;
    background-color: #339cff;
    vertical-align: -3px;
}
.status-label {
    font-size: smaller;
}
</style>339cff