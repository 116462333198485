<template>
    <div class="container pt-3 pb-3" style="background-color: white; border-bottom: 1px solid #ccc; border-left: 1px solid #ccc; border-right: 1px solid #ccc;">
        <div class="mt-3">
            <table>
                <tbody>
                    <tr>
                        <td>År:</td>
                        <td>
                            <select id="formPeriod" class="form-select" v-model="data.period" @change="emit('reload')">
                                <option value="all">Alla</option>
                                <option v-for="period in periods" :key="period" :value="period">{{ period }}</option>
                            </select>
                        </td>
                        <td>&nbsp;&nbsp;Typ:</td>
                        <td>
                            <select id="formType" class="form-select" v-model="data.type" @change="emit('reload')">
                                <option value="all">Alla</option>
                                <option v-for="t in data.types" :key="t" :value="t">{{ t }}</option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="mt-3">
            <table class="table">
                <thead>
                    <tr>
                        <th>År</th>
                        <th>Typ</th>
                        <th>Filnamn</th>
                        <th>Uppladdat</th>
                        <th v-if="isAdmin">Nedladdningar av kund</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="doc in data.documents" :key="doc.id" :style="'font-weight: ' + ((isAdmin || doc.isDownloaded) ? 'normal' : 'bold')">
                        <td>{{ doc.period }}</td>
                        <td>{{ doc.type }}</td>
                        <td><a href="#" @click="downloadDocument(doc.id)">{{ doc.filename }}</a></td>
                        <td>{{ new Date(doc.created).toLocaleString('sv') }}</td>
                        <td v-if="isAdmin"><span :title="doc.downloadsTitle">{{ doc.downloadsCount }}</span></td>
                        <td>
                            <button v-if="isAdmin" class="btn btn-link" @click="deleteDocument(doc.id)">Radera fil</button>
                            <span v-if="!isAdmin && !doc.isDownloaded" class="text-success">Nytt!</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import documentsService from '@/services/documentsService'
import tokenService from '@/services/tokenService'

const props = defineProps({
    data: Object,
    category: String,
})

const emit = defineEmits(['reload', 'filter'])

const periods = ref()
const isAdmin = ref()

async function downloadDocument(id) {
    const file = (await documentsService.downloadDocument(id)).data
    const blob = new Blob([new Uint8Array(file.content.data)]);
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = file.filename
    link.click()
    URL.revokeObjectURL(link.href)
    emit('reload')
}

async function deleteDocument(id) {
    if (confirm('Filen kommer att oåterkalleligen tas bort. Är du säker?')) {
        await documentsService.deleteDocument(id)
        emit('reload')
    }
}

onMounted(() => {
    let now = new Date()
    periods.value = []
    for (let i = 0; i < 15; i++) {
        periods.value.push((now.getFullYear() - i).toString())
    }
    isAdmin.value = tokenService.getUser().level <= 10
})
</script>