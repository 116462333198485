<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <h2>Användare</h2>
            </div>
            <div class="col">
                <div class="float-top float-end">
                    <button class="btn btn-primary ms-3" v-if="isAdmin" @click="showEditModal(null)">Lägg till användare</button>
                    <button class="btn btn-primary ms-3" v-if="isAdmin" @click="showTenantModal()">Skapa boendeinloggningar</button>
                </div>
            </div>
        </div>
        <div class="row mt-3" style="background-color: white; border: 1px solid #ccc;">
            <form>
                <div class="row pb-3 pt-3">
                    <div class="col-3">
                        <label for="formLevel" class="form-label">Behörighetsnivå</label>
                        <select id="formLevel" class="form-select" v-model="form.level" @change="update">
                            <option value="1">Administratörer</option>
                            <option value="10">Partners</option>
                            <option value="20">Kund</option>
                            <option value="30">Förvaltare</option>
                            <option value="40">Boende</option>
                        </select>
                    </div>
                    <div class="col-3" v-show="form.level > 10">
                        <label for="formCustomer" class="form-label">Kund</label>
                        <select id="formCustomer" class="form-select" v-model="form.customer" @change="changeFormCustomer">
                            <option v-for="c in customers" :key="c.id" :value="c.id">{{ c.name }}</option>
                        </select>
                    </div>
                    <div class="col-3" v-show="form.level > 20">
                        <label for="formSite" class="form-label">Anläggning</label>
                        <select id="formSite" class="form-select" v-model="form.site" @change="changeFormSite">
                            <option v-for="s in customerSites[form.customer]" :key="s.id" :value="s.id">{{ s.name }}</option>
                        </select>
                    </div>
                    <div class="col-3" v-show="form.level > 30">
                        <label for="formApt" class="form-label">Mätplats</label>
                        <select id="formApt" class="form-select" v-model="form.apartment" @change="update">
                            <option value="all">Alla</option>
                            <option v-for="a in form.apartments" :key="a.id" :value="a.id">{{ a.number }}</option>
                        </select>
                    </div>
                </div>
            </form>    
        </div>
        <div class="row mt-3">
            <div v-if="users && users.length > 0" style="background-color: white; border: 1px solid #ccc;">
                <table v-if="form.level <= 30" class="table">
                    <thead>
                        <tr>
                            <th>Namn</th>
                            <th>e-postadress</th>
                            <th>Låst</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="user in users" :key="user.id">
                            <td>{{ user.name }}</td>
                            <td>{{ user.email }}</td>
                            <td>
                                <i class="bi bi-lock-fill text-danger" v-show="user.is_locked"></i>
                            </td>
                            <td class="text-end">
                                <button class="btn btn-link btn-sm" @click="showEditModal(user.id)">Redigera</button>
                                <button class="btn btn-link btn-sm" @click="deleteUser(user.id)" :disabled="user.id == loggedInUser?.id">
                                    Ta bort
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table v-if="form.level == 40" class="table">
                    <thead>
                        <tr>
                            <th>Användarnamn</th>
                            <th>Mätplats</th>
                            <th>Inflyttad</th>
                            <th>Utflyttad</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="user in users" :key="user.id">
                            <td>{{ user.username }}</td>
                            <td>{{ user.number }}</td>
                            <td>{{ user.start }}</td>
                            <td>{{ user.stop }}</td>
                            <td>
                                <i class="bi bi-exclamation-triangle-fill text-warning" v-show="user.warning" :title="user.warning"></i>
                                <i class="bi bi-lock-fill text-danger" v-show="user.is_locked" title="Användaren är utelåst"></i>
                            </td>
                            <td class="text-end">
                                <button class="btn btn-link btn-sm" @click="showEditModal(user.id)">Redigera</button>
                                <button class="btn btn-link btn-sm" @click="deleteUser(user.id)" :disabled="user.id == loggedInUser?.id">
                                    Ta bort
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-else-if="users && users.length === 0">
                Inga användare fanns
            </div>
        </div>

        <div class="modal modal-lg" id="editModal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">{{ edit.id ? 'Redigera användare' : 'Lägg till användare' }}</h4>
                        <button type="button" class="btn-close" aria-label="Close" @click="editModal.hide()"></button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="row" v-show="isAdmin">
                                <div class="col-6">
                                    <div>
                                        <label for="editLevel" class="form-label">Behörighetsnivå</label>
                                        <select id="editLevel" class="form-select" v-model="edit.level" :disabled="edit.id"
                                            :title="edit.id ? 'Kan ej ändras på befintlig användare. Ta istället bort denna användaren och skapa en ny.' : null"
                                        >
                                            <option value="1">Administratör</option>
                                            <option value="10">Partner</option>
                                            <option value="20">Kund</option>
                                            <option value="30">Förvaltare</option>
                                            <option value="40">Boende</option>
                                        </select>
                                        <div class="text-danger" v-show="errors.levelMissing">Vänligen välj en behörighetsnivå</div>
                                    </div>
                                    <div class="mt-3" v-show="edit.level < 40 || edit.id">
                                        <label for="editUsername" class="form-label">Användarnamn</label>
                                        <input id="editUsername" type="text" class="form-control" v-model="edit.username" 
                                            :disabled="edit.level == 40" />
                                        <div class="text-danger" v-show="errors.usernameMissing">Vänligen ange ett användarnamn</div>
                                        <div class="text-danger" v-show="errors.usernameTaken">Använarnamnet är redan taget</div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="alert alert-warning mt-4" v-show="edit.level == 1">
                                        <strong>Administratör:</strong>
                                        Kan se alla kunder och har alla administratörsrättigheter.
                                    </div>
                                    <div class="alert alert-info mt-4" v-show="edit.level == 10">
                                        <strong>Partner:</strong>
                                        Kan se alla anläggningar under flera kunder och har vissa administratörsrättigheter.
                                    </div>
                                    <div class="alert alert-info mt-4" v-show="edit.level == 20">
                                        <strong>Kund:</strong>
                                        Kan se alla anläggningar under en kund.
                                    </div>
                                    <div class="alert alert-info mt-4" v-show="edit.level == 30">
                                        <strong>Förvaltare:</strong>
                                        Kan se alla mätplatser under en anläggning.
                                    </div>
                                    <div class="alert alert-info mt-4" v-show="edit.level == 40">
                                        <strong>Boende:</strong>
                                        Kan se en mätplats, och endast boende-sidan.
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3 row" v-show="edit.level < 40">
                                <div class="col-6">
                                    <label for="editPassword" class="form-label">Lösenord</label>
                                    <input id="editPassword" type="password" class="form-control" v-model="edit.password" />
                                    <div class="text-danger" v-show="errors.passwordTooShort">Behöver vara minst 8 tecken</div>
                                </div>
                                <div class="col-6">
                                    <label for="editConfirm" class="form-label">Bekräfta lösenord</label>
                                    <input id="editConfirm" type="password" class="form-control" v-model="edit.confirm" />
                                    <div class="text-danger" v-show="errors.passwordMismatch">Stämmer inte med lösenordet</div>
                                </div>
                            </div>
                            <div class="row mt-3" v-show="edit.level < 40">
                                <div class="col-6">
                                    <label for="editName" class="form-label">Namn</label>
                                    <input id="editName" type="text" class="form-control" v-model="edit.name" />
                                    <div class="text-danger" v-show="errors.nameMissing">Vänligen ange ett namn</div>
                                </div>
                                <div class="col-6">
                                    <label for="editEmail" class="form-label">e-postadress</label>
                                    <input id="editEmail" type="text" class="form-control" v-model="edit.email" />
                                    <div class="text-danger" v-show="errors.emailMissing">Vänligen ange en e-postadress</div>
                                    <div class="text-danger" v-show="errors.emailInvalid">e-postadressen är felaktig</div>
                                </div>
                            </div>
                            <div class="form-check mt-3" v-show="edit.id">
                                <input id="editLocked" type="checkbox" class="form-check-input" v-model="edit.locked"/>
                                <label for="editLocked" class="form-label">Låst</label>
                            </div>
                            <div class="form-check mt-3" v-show="edit.id && edit.level == 40">
                                <input id="editGeneratePassword" type="checkbox" class="form-check-input" v-model="edit.generatePassword"/>
                                <label for="editGeneratePassword" class="form-label">Generera nytt lösenord</label>
                            </div>
                            <div class="row mt-3" v-show="isAdmin && edit.level == 10">
                                <label for="editCustomer" class="form-label">Kunder</label>
                                <div class="col card bg-light ms-3 me-3" id="editCustomer">
                                    <div class="row mt-3 mb-3">
                                        <div class="col-5">
                                            <select class="form-select" v-model="edit.customer">
                                                <option v-for="c in customers" :key="c.id" :value="c.id">{{ c.name }}</option>
                                            </select>
                                        </div>
                                        <div class="col-1">
                                            <button class="btn btn-secondary" @click.prevent="addEditCustomer()" :disabled="!edit.customer">+</button>
                                        </div>
                                        <div class="col-6">
                                            <div class="row" v-for="ec in edit.customers" :key="ec.id">
                                                <div class="col-10">
                                                    {{ ec.name }}
                                                </div>
                                                <div class="col-2">
                                                    <button class="btn btn-link text-end text-danger" @click.prevent="removeEditCustomer(ec.id)">
                                                        <i class="bi bi-dash-circle"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="mb-3" v-show="edit.customers.length == 0">Inga kunder tillagda</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-danger mt-3" v-show="errors.customersMissing">Minst en kund måste läggas till</div>
                            </div>
                            <div class="row mt-3" v-show="!edit.id">
                                <div class="col-6" v-show="isAdmin && edit.level >= 20">
                                    <label for="editCustomer" class="form-label">Kund</label>
                                    <select class="form-select" v-model="edit.customer" @change="updateEditCustomer">
                                        <option v-for="c in customers" :key="c.id" :value="c.id">{{ c.name }}</option>
                                    </select>
                                    <div class="text-danger mt-3" v-show="errors.customerMissing">Vänligen välj en kund</div>
                                </div>
                                <div class="col-6" v-show="isAdmin && edit.level >= 30">
                                    <label for="editSite" class="form-label">Anläggning</label>
                                    <select id="editSite" class="form-select" v-model="edit.site" @change="updateEditSite">
                                        <option v-for="s in customerSites[edit.customer]" :key="s.id" :value="s.id">{{ s.name }}</option>
                                    </select>
                                    <div class="text-danger" v-show="errors.siteMissing">Vänligen välj en anläggning</div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-6" v-show="!edit.id && isAdmin && edit.level >= 40 || loggedInUser?.level > 1">
                                    <label for="editApartment" class="form-label">Mätplats</label>
                                    <select id="editApartment" class="form-select" v-model="edit.apartment">
                                        <option v-for="a in apartments" :key="a.id" :value="a.id">{{ a.number + (a.address ? ` (${a.address})` : '') }}</option>
                                    </select>
                                    <div class="text-danger" v-show="errors.apartmentMissing">Vänligen välj en mätplats</div>
                                </div>
                            </div>
                            <div class="row mt-3" v-show="edit.level == 40">
                                <div class="col-6">
                                    <label for="editStart" class="form-label">Inflyttningsdatum</label>
                                    <input id="editStart" type="date" class="form-control" v-model="edit.start" />
                                    <div class="text-danger mt-3" v-show="errors.startInvalid">Felaktig datumangivelse</div>
                                </div>
                                <div class="col-6">
                                    <label for="editStop" class="form-label">Utflyttningsdatum</label>
                                    <input id="editStop" type="date" class="form-control" v-model="edit.stop" />
                                    <div class="text-danger mt-3" v-show="errors.stopInvalid">Felaktig datumangivelse</div>
                                </div>
                            </div>
                            <div class="alert alert-warning mt-3" v-show="edit.level == 40 && !edit.start && !edit.stop">
                                <strong>Varning!</strong>
                                Om ingen in- eller utflyttningsdatum anges kan användaren se all data som mätts på 
                                mätplatsen, även då dem ej var i besittning.
                            </div>
                        </form>
                        <div v-show="errorMessage" class="text-danger mt-3">{{ errorMessage }}</div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="editModal.hide()">Avbryt</button>
                        <button type="button" class="btn btn-primary" @click="submitEditModal" :disabled="editClicked">Spara</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="loginModal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Nyskapad användare</h4>
                        <button type="button" class="btn-close" aria-label="Close" @click="loginModal.hide()"></button>
                    </div>
                    <div class="modal-body">
                        <div class="mt-3">Användarnamn: {{ login.username }}</div>
                        <div>Lösenord: {{ login.password }}</div>
                        <button type="button" class="btn btn-primary mt-3" id="deleteButton" @click="copyToClipboard">Kopiera till urklipp</button>
                        <div class="text-success" v-show="login.copied">Kopierat till urklipp</div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="loginModal.hide()">Stäng</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="tenantModal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Skapa boendeinloggningar</h4>
                        <button type="button" class="btn-close" aria-label="Close" @click="tenantModal.hide()"></button>
                    </div>
                    <div class="modal-body">
                        <div class="alert alert-info">
                            <strong>Observera!</strong>
                            Denna funktion skapar inloggningar för alla mätplatser på en anläggning. Om det endast är enskilda 
                            boende som skall ha inloggning så använd hellre "Lägg till användare".
                        </div>
                        <div class="mt-3">
                            <label for="tenantCustomer" class="form-label">Kund</label>
                            <select id="tenantCustomer" class="form-select" v-model="tenant.customer" @change="changeTenantCustomer">
                                <option v-for="c in customers" :key="c.id" :value="c.id">{{ c.name }}</option>
                            </select>
                            <div class="text-danger" v-show="errors.customerMissing">Vänligen välj en kund</div>
                        </div>
                        <div class="mt-3">
                            <label for="editSite" class="form-label">Anläggning</label>
                            <select id="editSite" class="form-select" v-model="tenant.site">
                                <option v-for="s in customerSites[tenant.customer]" :key="s.id" :value="s.id">{{ s.name }}</option>
                            </select>
                            <div class="text-danger" v-show="errors.siteMissing">Vänligen välj en anläggning</div>
                        </div>
                        <button type="button" class="btn btn-primary mt-3" id="downloadButton" @click="createTenantFile">Skapa fil</button>
                        <div class="alert alert-warning mt-3">
                            <strong>Observera!</strong>
                            Filen som skapas här är den enda källan till
                            dessa lösenord, då de inte finns lagrade någon annanstans.
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="tenantModal.hide()">Stäng</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import sitesService from '@/services/sitesService'
import tokenService from '../services/tokenService'
import usersService from '@/services/usersService'
import { Modal } from 'bootstrap'
import apartmentsService from '@/services/apartmentsService'
import helpers from '@/helpers/helpers'
import dateTime from '@/helpers/dateTime'

export default {
    data() {
        return {
            // Modals
            editModal: null,
            loginModal: null,
            tenantModal: null,
            // customersModal: null,
            // deleteModal: null,
            // User
            isAdmin: false,
            loggedInUser: null,
            editClicked: false,
            // Content data
            users: null,
            customers: [],
            customerSites: {},
            apartments: [],
            // Form data
            login: {
                username: null,
                password: null,
                copied: false,
            },
            form: {
                level: null,
                customer: null,
                site: null,
                apartment: null,
                apartments: [],
            },
            edit: {
                id: null, 
                level: null,
                customer: null,
                customers: [],
                site: null,
                apartment: null,
                locked: false,
                password: null,
                confirm: null,
                start: null,
                stop: null,
                username: null,
                name: null,
                email: null,
                generatePassword: false,
            },
            tenant: {
                customer: null,
                site: null,
            },
            errors: {
                levelMissing: false,
                passwordTooShort: false,
                passwordMismatch: false,
                usernameMissing: false,
                nameMissing: false,
                emailMissing: false,
                emailInvalid: false,
                customersMissing: false,
                customerMissing: false,
                siteMissing: false,
                apartmentMissing: false,
                startInvalid: false,
                stopInvalid: false,
            },
            errorMessage: null,
        }
    },
    methods: {
        changeFormCustomer() {
            const level = Number.parseInt(this.form.level)
            if (level >= 30) {
                if (this.customerSites[this.form.customer].length === 1) {
                    this.form.site = this.customerSites[this.form.customer][0].id
                    this.changeFormSite()
                    return
                } else {
                    this.form.site = null
                    this.form.apartment = null
                    this.form.apartments = []
                }
            }
            this.update()
        },
        async changeFormSite() {
            if (this.form.site) {
                this.form.apartments = (await apartmentsService.getApartments(this.form.site)).data
            }
            this.form.apartment = 'all'
            this.update()
        },
        async deleteUser(id) {
            if (confirm('Ta bort användaren?')) {
                await usersService.deleteUser(id)
                this.update()
            }
        },
        async update() {
            if (!this.form.level) {
                this.users = null
                return
            }
            let params = { level: Number.parseInt(this.form.level) }
            switch (params.level) {
                case 20:
                    if (!this.form.customer) {
                        this.users = null
                        return
                    }
                    params.customer = this.form.customer
                    break
                case 30:
                    if (!this.form.customer) {
                        this.users = null
                        return
                    }
                    params.customer = this.form.customer
                    if (this.form.site) {
                        params.site = this.form.site
                    }
                    break
                case 40:
                    if (!this.form.customer || !this.form.site) {
                        this.users = null
                        return
                    }
                    if (this.form.apartment && this.form.apartment != 'all') {
                        params.apartment = this.form.apartment
                    } else {
                        params.site = this.form.site
                    }
                    break
            }
            const users = (await usersService.getUsers(params)).data

            if (params.level === 40) {
                for (let user of users) {
                    if (!user.start && !user.stop) {
                        const others = users.filter(u => u.apartment_id == user.apartment_id)
                        if (others.length > 1) {
                            user.warning = 'Det finns flera användare på denna mätplats och inga in- och utflyttningsdatum.'
                        }
                    }
                    if (user.start) {
                        user.start = dateTime.getDateString(new Date(user.start))
                    }
                    if (user.stop) {
                        user.stop = dateTime.getDateString(new Date(user.stop))
                    }
                }
            }

            this.users = users
        },
        updateEditCustomer() {
            if (this.customerSites[this.edit.customer].length == 1) {
                const level = Number.parseInt(this.form.level)
                this.edit.site = this.customerSites[this.edit.customer][0].id
                if (this.edit.level >= 30) {
                    this.updateEditSite()
                } else {
                    this.edit.apartment = null
                }
            } else {
                this.edit.site = null
                this.edit.apartment = null
            }
        },
        async updateEditSite() {
            if (this.edit.site) {
                this.apartments = (await apartmentsService.getApartments(this.edit.site)).data
            }
            this.edit.apartment = null
        },
        showTenantModal() {
            this.tenant.customer = null
            this.tenant.site = null
            this.errors.customerMissing = false
            this.errors.siteMissing = false
            this.tenantModal.show()
        },
        changeTenantCustomer() {
            if (this.customerSites[this.tenant.customer].length == 1) {
                this.tenant.site = this.customerSites[this.tenant.customer][0].id
            } else {
                this.tenant.site = null
            }
        },
        async createTenantFile() {
            if (!this.tenant.customer) {
                this.errors.customerMissing = true
            }
            if (!this.tenant.site) {
                this.errors.siteMissing = true
            }

            const logins = (await usersService.createApartmentLogins(this.tenant.site)).data

            let lines = [ 'Nummer;Adress;Användarnamn;Lösenord' ]
            for (const login of logins) {
                lines.push(`${login.number};${login.address};${login.username};${login.password}`)
            }

            const site = this.customerSites[this.tenant.customer].find(s => s.id == this.tenant.site)
            const timestamp = dateTime.getDateTimeString(new Date())

            const blob = new Blob([lines.join('\r\n')])
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = `Inloggningar ${site.name} ${timestamp}.csv`
            link.click()
            URL.revokeObjectURL(link.href)
        },
        async showEditModal(userId) {
            this.clearFormErrors()
            if (userId) {
                const user = this.users.find(u => u.id == userId)
                this.edit.id = userId
                this.edit.level = user.level
                this.edit.locked = user.is_locked
                this.edit.name = user.name
                this.edit.email = user.email
                this.edit.username = user.username
                if (user.level == 10) {
                    this.edit.customers = (await usersService.getUserCustomers(userId)).data
                }
                if (user.level >= 40) {
                    this.edit.start = user.start
                    this.edit.stop = user.stop
                }
                this.edit.generatePassword = false
            } else {
                this.edit.id = null
                this.edit.level = null
                this.edit.password = null
                this.edit.confirm = null
                this.edit.username = null
                this.edit.name = null
                this.edit.email = null
                this.edit.customer = null
                this.edit.customers = []
                this.edit.site = null
                this.edit.apartment = null
                this.edit.locked = false
                this.edit.start = null
                this.edit.stop = null
                this.edit.generatePassword = false
                // Pre-select from filter
                if (this.form.level) {
                    this.edit.level = this.form.level
                    if (this.form.level >= 20 && this.form.customer) {
                        this.edit.customer = this.form.customer
                        if (this.form.level >= 30 && this.form.site) {
                            this.edit.site = this.form.site
                            await this.updateEditSite()
                            if (this.form.level >= 40 && this.form.apartment) {
                                this.edit.apartment = this.form.apartment
                            }
                        }
                    }
                }
            }
            this.editModal.show()
        },
        addEditCustomer() {
            if (!this.edit.customers.find(c => c.id == this.edit.customer)) {
                this.edit.customers.push(this.customers.find(c => c.id == this.edit.customer))
            }
        },
        removeEditCustomer(id) {
            const index = this.edit.customers.findIndex(c => c.id == id)
            if (index >= 0) {
                this.edit.customers.splice(index, 1)
            }

        },
        clearFormErrors() {
            this.errors.levelMissing = false
            this.errors.passwordTooShort = false
            this.errors.passwordMismatch = false
            this.errors.usernameMissing = false
            this.errors.nameMissing = false
            this.errors.emailMissing = false
            this.errors.emailInvalid = false
            this.errors.customersMissing = false
            this.errors.customerMissing = false
            this.errors.siteMissing = false
            this.errors.apartmentMissing = false
            this.errors.startInvalid = false
            this.errors.stopInvalid = false
            this.errorMessage = null
        },
        async submitEditModal() {
            this.editClicked = true
            this.clearFormErrors()
            if (this.edit.id) {
                let valid = true
                const level = Number.parseInt(this.edit.level)

                if (isNaN(level)) {
                    this.errors.levelMissing = true
                    valid = false
                }
                if (level < 40) {
                    if (this.edit.password && this.edit.password.length < 8) {
                        this.errors.passwordTooShort = true
                        valid = false
                    }
                    if (this.edit.password !== this.edit.confirm) {
                        this.errors.passwordMismatch = true
                        valid = false
                    }
                    if (!this.edit.username || !this.edit.username.trim()) {
                        this.errors.usernameMissing = true
                        valid = false
                    }
                    if (!this.edit.name || !this.edit.name.trim()) {
                        this.errors.nameMissing = true
                        valid = false
                    }
                    if (!this.edit.email || !this.edit.email.trim()) {
                        this.errors.emailMissing = true
                        valid = false
                    } else if (!helpers.validateEmail(this.edit.email)) {
                        this.errors.emailInvalid = true
                        valid = false
                    }
                }
                if (level === 10 && this.edit.customers.length < 1) {
                    this.errors.customersMissing = true
                    valid = false
                }
                if (level >= 40) {
                    if (this.edit.start && isNaN(Date.parse(this.edit.start))) {
                        this.errors.startInvalid = true
                        valid = false
                    }
                    if (this.edit.stop && isNaN(Date.parse(this.edit.stop))) {
                        this.errors.stopInvalid = true
                        valid = false
                    }
                }

                if (!valid) {
                    this.editClicked = false
                    return
                }

                try {
                    const user = await usersService.updateUser(this.edit)
                    this.update()
                    this.editModal.hide()
                    if (level === 40 && this.edit.generatePassword) {
                        this.login.copied = false
                        this.login.username = user.data.username
                        this.login.password = user.data.password
                        this.loginModal.show()
                    }
                } catch(err) {
                    if (err.response) {
                        if (err.response.status === 409) {
                            this.errors.usernameTaken = true
                        } else {
                            this.errorMessage = `(${err.response.status}) ${err.response.statusText}: ${err.response.data}`
                        }
                    } else {
                        this.errorMessage = 'Okänt fel. Kontakta administratör.'
                    }
                }
            } else {
                let valid = true
                const level = Number.parseInt(this.edit.level)

                if (isNaN(level)) {
                    this.errors.levelMissing = true
                    valid = false
                }
                if (level < 40) {
                    if (!this.edit.password || this.edit.password.length < 8) {
                        this.errors.passwordTooShort = true
                        valid = false
                    }
                    if (this.edit.password !== this.edit.confirm) {
                        this.errors.passwordMismatch = true
                        valid = false
                    }
                    if (!this.edit.username || !this.edit.username.trim()) {
                        this.errors.usernameMissing = true
                        valid = false
                    }
                    if (!this.edit.name || !this.edit.name.trim()) {
                        this.errors.nameMissing = true
                        valid = false
                    }
                    if (!this.edit.email || !this.edit.email.trim()) {
                        this.errors.emailMissing = true
                        valid = false
                    } else if (!helpers.validateEmail(this.edit.email)) {
                        this.errors.emailInvalid = true
                        valid = false
                    }
                }
                if (level === 10 && this.edit.customers.length < 1) {
                    this.errors.customersMissing = true
                    valid = false
                }
                if (level >= 20 && !this.edit.customer) {
                    this.errors.customerMissing = true
                    valid = false
                }
                if (level >= 30 && !this.edit.site) {
                    this.errors.siteMissing = true
                    valid = false
                }
                if (level >= 40) {
                    if (!this.edit.apartment) {
                        this.errors.apartmentMissing = true
                        valid = false
                    }
                    if (this.edit.start && isNaN(Date.parse(this.edit.start))) {
                        this.errors.startInvalid = true
                        valid = false
                    }
                    if (this.edit.stop && isNaN(Date.parse(this.edit.stop))) {
                        this.errors.stopInvalid = true
                        valid = false
                    }
                }

                if (!valid) {
                    this.editClicked = false
                    return
                }

                try {
                    const user = await usersService.createUser(this.edit)
                    this.update()
                    this.editModal.hide()
                    if (level === 40) {
                        this.login.copied = false
                        this.login.username = user.data.username
                        this.login.password = user.data.password
                        this.loginModal.show()
                    }
                } catch(err) {
                    if (err.response) {
                        if (err.response.status === 409) {
                            this.errors.usernameTaken = true
                        } else {
                            this.errorMessage = `(${err.response.status}) ${err.response.statusText}: ${err.response.data}`
                        }
                    } else {
                        this.errorMessage = 'Okänt fel. Kontakta administratör.'
                    }
                }
            }
            this.editClicked = false
        },
        copyToClipboard() {
            navigator.clipboard.writeText([
                'Adress: ' + window.location.protocol + '//' + window.location.host,
                'Användarnamn: ' + this.login.username,
                'Lösenord: ' + this.login.password,
            ].join('\r\n'))
            this.login.copied = true
        },
        showDeleteModal(siteId) {
        },
        async submitDeleteModal() {
        },
    },
    async mounted() {
        this.editModal = new Modal(document.getElementById('editModal'))
        this.loginModal = new Modal(document.getElementById('loginModal'))
        this.tenantModal = new Modal(document.getElementById('tenantModal'))
        // this.deleteModal = new Modal(document.getElementById('deleteModal'))
        // this.customersModal = new Modal(document.getElementById('customersModal'))
        this.isAdmin = tokenService.getUserIsAdmin()
        this.loggedInUser = tokenService.getUser()

        const sites = (await sitesService.getSites())?.data
        let customers = []
        let customerSites = {}
        for (let site of sites) {
            if (!(site.customer_id in customerSites)) {
                customers.push({ id: site.customer_id, name: site.customer_name })
                customerSites[site.customer_id] = []
            }
            customerSites[site.customer_id].push({ id: site.id, name: site.name })
        }

        this.customers = customers
        this.customerSites = customerSites
    },
    beforeRouteLeave(to, from, next) {
        this.editModal.hide()
        this.loginModal.hide()
        this.tenantModal.hide()
        // this.deleteModal.hide()
        // this.customersModal.hide()
        next()
    },
}
</script>