<template>
    <div class="modal" id="importModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Importera från fil</h4>
                    <button type="button" class="btn-close" aria-label="Close" @click="$emit('close')"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="mb-3">
                            <label for="formFile" class="form-label">Välj fil <i class="bi bi-info-circle" title="För vidare instruktioner, välj först Exportera till fil och läs kommentarerna i filen."></i></label>
                            <input class="form-control" type="file" id="fileInput">
                            <div class="text-danger" v-show="noFileSelected">Vänligen välj en fil att ladda upp</div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="$emit('close')">Avbryt</button>
                    <button type="button" class="btn btn-primary" @click="submit">Importera</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import sitesService from '@/services/sitesService'

export default {
    props: { data: Object },
    emits: [ 'submit', 'close', 'reload' ],
    data() {
        return {
            noFileSelected: false,    
        }
    },
    methods: {
        async submit() {
            const file = document.getElementById("fileInput").files[0]
            if (!file) {
                this.noFileSelected = true
                return
            }
            
            this.$nextTick(() => { 
                this.$emit('close')
            })

            let changes = []
            let errors = []

            try {
                const res = await sitesService.uploadFile(this.data.siteId, file)
               
                if (res.data.length > 0) {
                    for (let c of res.data) {
                        let msg
                        switch (c.message) {
                            case 'NEW_APARTMENT':
                                msg = 'Ny mätplats'
                                break
                            case 'UPDATED_APARTMENT':
                                msg = 'Ändringar i mätplats'
                                break
                            case 'NEW_MP_AND_DEVICE':
                                msg = 'Ny mätpunkt och enhet'
                                break
                            case 'UPDATED_MP':
                                msg = 'Ändringar i mätpunkt'
                                break
                            case 'UPDATED_DEVICE':
                                msg = 'Ändringar i enhet'
                                break
                            default:
                                msg = 'Okänd ändring'
                                break
                        }
                        changes.push(`${c.number}: ${msg}`)
                    }
                }
            } catch (err) {
                if (err.response?.status === 400) {

                    for (let error of err.response.data.errors) {
                        let msg
                        switch (error.message) {
                            case 'MISSING_NUMBER':
                                msg = 'Mätplatsnummer saknas'
                                break
                            case 'APARTMENT_NUMBER_TAKEN':
                                msg = 'Mätplatsnummer används på en annan mätplats'
                                break
                            case 'ID_NUMBER_TAKEN':
                                msg = 'ID-numret används på en annan mätare i en annan mätplats'
                                break
                            case 'DEVICE_NUMBER_TAKEN':
                                msg = 'Mätarnumret används på en annan mätare'
                                break
                            case 'MISSING_TYPE':
                                msg = 'Typ saknas'
                                break
                            case 'MISSING_ID':
                                msg = 'ID-nummer saknas'
                                break
                            case 'INVALID_FACTOR':
                                msg = 'Faktor är inte ett heltal'
                                break
                            case 'INVALID_FREQUENCY':
                                msg = 'Okänd angivele av frekvens'
                                break
                            case 'UNKNOWN_TYPE':
                                msg = 'Okänd typangivelse'
                                break
                            default:
                                msg = 'Okänt fel'
                                break
                        }

                        errors.push(`Rad ${error.line}: ${msg}`)
                    }
                }
            }
            this.data.changes = changes
            this.data.errors = errors

            if (changes.length > 0) {
                this.$emit('reload')    
            }

            this.$emit('submit')
        },
    },
    watch: {
        data: {
            handler() {
                document.getElementById("fileInput").value = ''
                this.noFileSelected = false
            }
        }
    },
}
</script>