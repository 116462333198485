import api from './api'

class InvoiceDataService {
    getSiteInvoiceData(siteId) {
        return api.get(`/sites/${siteId}/invoice_data`)
    }

    sendToFortnox(invoiceDataId, siteId) {
        return api.post(`/invoice_data/${invoiceDataId}/send_to_fortnox`, { siteId })
    }

    getAuthCode(id) {
        return api.get(`/invoice_data/${id}/get_auth_code`)
    }

    fortnoxApiAuth(code, state) {
        return api.get(`/invoice_data/fortnox_api_auth?code=${code}&state=${state}`)
    }

    uploadInvoiceData(data) {
        let formData = new FormData()

        formData.append('file', data.file)

        return api.post(`/sites/${data.siteId}/upload_invoice_data`,
            formData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
        )
    }

    deleteInvoiceData(invoiceDataId) {
        return api.delete(`/invoice_data/${invoiceDataId}`)
    }
}

export default new InvoiceDataService()