<template>
    <div class="modal" id="importModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Importera avläsningar från fil</h4>
                    <button type="button" class="btn-close" aria-label="Close" @click="$emit('close')"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="mb-3">
                            <label for="formFile" class="form-label">Välj fil</label>
                            <input ref="fileInput" class="form-control" type="file" id="fileInput"/>
                        </div>
                        <div class="form-check" v-if="data.enableAdvanced">
                            <input id="advCheck" type="checkbox" class="form-check-input" v-model="data.showAdvanced"/>
                            <label for="advCheck">Avancerat</label>
                        </div>
                        <div v-show="data.showAdvanced">
                            <div>
                                <label for="columnsInput" class="form-label">Kolumner (SKV)</label>
                                <input id="columnsInput" class="form-control" v-model="data.columns" type="text"/>
                            </div>
                        </div>
                    </form>
                    <div v-show="data.errorMessage" class="text-danger">
                        {{ data.errorMessage }}
                    </div>
                    <div v-show="data.successMessages">
                        <div v-for="line in data.successMessages">
                            {{ line }}
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="$emit('close')">{{ data.successMessages ? 'Stäng' : 'Avbryt' }}</button>
                    <button type="button" class="btn btn-primary" :disabled="data.successMessages" @click="onSubmit">Importera</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue'
import { useRoute } from 'vue-router'
import importService from '@/services/importService'
import sitesService from '@/services/sitesService'

const props = defineProps({
    data: Object
})

const emit = defineEmits([ 'close', 'reload' ])

const fileInput = ref(null)
const route = useRoute()

async function onSubmit() {
    props.data.errorMessage = null
    props.data.successMessages = null
    
    const keyRes = await sitesService.getFirstValidApiKey(route.params.id)

    const apiKey = keyRes.data.key
    if (!apiKey) {
        props.data.errorMessage = 'API-nyckel saknas.'
        return
    }

    const file = fileInput.value.files[0]
    if (!file) {
        props.data.errorMessage = 'Ingen fil vald'
        return
    }

    let columns = null
    if (props.data.showAdvanced && props.data.columns) {
        columns = []
        const parts = props.data.columns.split(';')
        let hasId = false, hasTimestamp = false, hasValue = false
        for (const part of parts) {
            if (part.length === 0) {
                columns.push(null)
            } else {
                if (part === 'id') {
                    hasId = true
                } else if (part === 'timestamp') {
                    hasTimestamp = true
                } else {
                    hasValue = true
                }
                columns.push(part)
            }
        }

        if (!hasId) {
            props.data.errorMessage = 'Saknar ID-kolumn'
            return
        }
        if (!hasTimestamp) {
            props.data.errorMessage = 'Saknar datum/tid-kolumn'
            return
        }
        if (!hasValue) {
            props.data.errorMessage = 'Saknar värdes-kolumn'
            return
        }
    }

    const reader = new FileReader()
    let content = null

    reader.onload = res => {
        content = res.target.result
    }
    reader.onerror = () => {
        props.data.errorMessage = 'Fel vid inläsning av filen'
    }
    reader.onloadend = async () => {
        try {
            if (columns) {
                await importService.updateImportCsvFormat(content, JSON.stringify(columns))
            }

            const importResult = (await importService.importReadings(content, file.name, apiKey)).data
            props.data.successMessages = [
                'Resultat av import:',
                `${importResult.valid} avläsningar importerades`,
                `${importResult.invalid} avläsningar hade fel`,
                `${importResult.unknown} avläsningar hade okänt modulnr.`,
                `${importResult.empty} avläsningar saknade mätvärde`,
            ]
            if (importResult.valid > 0) {
                emit('reload')
            }
        } catch (error) {
            if (error.data) {
                props.data.errorMessage = 'Serven svarar: ' + error.data.status + ' ' + error.data.statusText
            } else {
                console.log(error)
                props.data.errorMessage = 'Annat fel'
            }
        }
    }
    reader.readAsText(file, 'latin1')
}
</script>