import helpers from './helpers'
import seriesHelpers from './seriesHelpers'
import sitesService from '@/services/sitesService'
import readingsService from '@/services/readingsService'

class DevicesHelpers {
    getDevicesCsvFile(apartments) {
        const measurementNames = {
            'cold_water': 'kallvatten',
            'hot_water': 'varmvatten',
            'heat': 'värme',
            'electricity': 'el',
            'hca': 'radiator',
            'ambiance': 'rum',
            'cold': 'kyla',
        }

        let lines = [
            '# Mätplatsnummer (behövs): Det nummer som används vid avräkning för lägenhet, hus, lokal, etc. Måste vara unikt för anläggningen.',
            '# Address (frivillig): Mätplatsens adress',
            '# Mätpunktsplats (frivillig): Där mätaren sitter, t ex badrum, kök, etc.',
            '# Mätpunktstyp (behövs): En av följande: kv (kallvatten), vv (varmvatten), värme, el, rum (rumsgivare)',
            '# ID-nummer (behövs): Sekundadressens ID för M-Bus eller DevEUI fär LoRaWAN',
            '# Mätarnummer (frivillig): Nummer på själva mätaren',
            '# Faktor (frivillig): Det tal som inkommande mätvärden skall multipliceras med för att enheten (m³ eller kWh) skall stämma',
            '# Rapporterar (frivillig): Hur ofta mätaren rapporterar, en av följande: 15, h, dag, månad, kvartal, år (eller antal minuter om annat)',
            '# Nytt mätplatsnummer (frivillig): Används om mätplatsens nummer skall bytas. Används bara om mätplatsen redan finns med numret från första kolumnen.',
            '#',
            '#Mätplatsnummer;Adress;Mätpunktsplats;Mätpunktstyp;ID-nummer;Mätarnummer;Faktor;Rapporterar;Nytt mätplatsnummer'
        ]
        for (const apartment of apartments) {
            for (const device of apartment.devices) {
                lines.push(`${apartment.number};${apartment.address??''};${device.place??''};${measurementNames[device.measurement]};${device.idNumber};${device.deviceNumber??''};${device.factor?.toLocaleString('sv')??''};${device.freqMinutes??''}`)
            }
        }

        return lines.join('\r\n')
    }

    async loadDevices(siteId) {
        const defaultField = {
            1: 'volume',
            2: 'volume',
            3: 'energy',
            4: 'energy',
            5: 'count',
            6: 'ext-temp',
            7: 'energy',
        }

        const units = {
            1: 'm³',
            2: 'm³',
            3: 'kWh',
            4: 'kWh',
            5: '',
            6: '°C',
            7: 'kWh',
        }

        const decimals = {
            1: 3,
            2: 3,
            3: 0,
            4: 0,
            5: 0,
            6: 1,
            7: 0,
        }

        function buildTitle(fields) {
            let lines = []
            for (const key in fields) {
                lines.push(`${seriesHelpers.getFieldLabel(key)}: ${new Number(fields[key]).toLocaleString('sv')} ${seriesHelpers.getFieldUnit(key)}`)
            }
            return lines.join('\n')
        }

        function buildWarning(measurement, status) {
            if (measurement != 'cold_water' && measurement != 'hot_water') {
                return `Status: ${status}\nOkänd betydelse.`
            }
            let lines = []
            if (status & 3) {
                lines.push('Vattenläcka (kontinuerlig förbrukning upptäckt)')
            }
            if (status & 4) {
                lines.push('Batterinivå låg / batteri borttaget')
            }
            if (status & 8) {
                lines.push('Modul borttagen')
            }
            if (status & 16) {
                lines.push('Optisk manipulation')
            }
            if (status & 32) {
                lines.push('Magnetisk manipulation')
            }
            if (status & 64) {
                lines.push('Backflöde (kontinuerligt och över satt tröskelvärde)')
            }
            if (status & 128) {
                lines.push('Q-max överflöde (mer än 10 min)')
            }
            return lines.join('\n')
        }

        function formatValue(value, field) {
            if (value != 0 && !value) {
                return ''
            }
            const precision = seriesHelpers.getFieldPrecision(field)
            return `${new Number(value).toFixed(precision).replace('.', ',')}`
        }

        function isLate(timestamp, freqMinutes) {
            let date = new Date()
            date.setDate(date.getDate() - 2)
            if (!freqMinutes) {
                return false
            }
            return new Date(timestamp) < date
        }

        function getColors(timestamp, freqMinutes, source = null) {
            if (source === 'walk-by') {
                return 'walkby-bg text-body'
            }
            if (!freqMinutes) {
                return ''
            }
            if (isLate(timestamp, freqMinutes)) {
                return 'bg-danger text-white'
            } 
            return 'text-success'
        }

        const res = await sitesService.getSiteDevices(siteId)
        const readings = (await readingsService.getSiteLatestReadings(siteId)).data

        let apartments = []
        let status = [ 0, 0, 0, 0 ] // Success, Warning, Danger, Walk-by
        let totalNew = 0

        for (const row of res.data) {
            let apartment = apartments.find(a => a.id == row.apartment_id)

            if (!apartment) {
                apartment = {
                    id: row.apartment_id,
                    number: row.apartment_number,
                    address: row.address,
                    type: row.apartment_type,
                    devices: []
                }
                apartments.push(apartment)
            }

            if (row.measuring_point_id) {
                const reading = readings.find(r => {
                    return r.measuringPointId == row.measuring_point_id && r.deviceId == row.device_id
                })

                if (reading) {
                    const late = isLate(reading.timestamp, row.freq_minutes)
                    if (reading.source === 'walk-by') {
                        status[3]++
                        if (!late) {
                            totalNew++
                        }
                    } else if (late) {
                        status[2]++
                    } else {
                        status[0]++
                        totalNew++
                    }
                } else {
                    status[1]++
                }

                const measurement = seriesHelpers.getMeasurementFromType(row.type)

                const values = []
                if (reading) {
                    if (row.extra?.show) {
                        for (const field in reading.fields) {
                            if (row.extra.show[field]) {
                                values.push({
                                    value: formatValue(reading.fields[field], field),
                                    field: seriesHelpers.getFieldShortLabel(field).toLowerCase(),
                                    unit: seriesHelpers.getFieldUnit(field),
                                })
                            }
                        }
                    } 
                    if (values.length === 0) {
                        const primaryFields = seriesHelpers.getMeasurementPrimaryFields(measurement)
                        for (const field of primaryFields) {
                            values.push({
                                value: reading ? formatValue(reading.fields[field], field) : '',
                                unit: reading ? seriesHelpers.getFieldUnit(field) : '',
                            })
                        }
                    }
                }

                apartment.devices.push({
                    measuringPointId: row.measuring_point_id,
                    measurement,
                    type: row.type,
                    place: row.place,
                    freqMinutes: row.freq_minutes,
                    idNumber: row.id_number,
                    deviceId: row.device_id,
                    factor: row.factor,
                    deviceNumber: row.device_number,
                    extra: row.extra,
                    timestamp: reading ? helpers.getDateTimeString(new Date(reading.timestamp)) : '',
                    values,
                    telegram: reading ? buildTitle(reading.fields) : '',
                    warning: reading && reading.fields['status'] ? buildWarning(measurement, reading.fields['status']) : null,
                    fields: reading ? reading.fields : [],
                    colors: reading ? getColors(reading.timestamp, row.freq_minutes, reading.source) : 'bg-warning text-dark',
                })
            }
        }
        this.sortApartments(apartments)

        const count = status[0] + status[1] + status[2] + status[3]
        let statusConfig = { status, percentage: Math.floor(100 * totalNew / count ) }
        const lateShare = status[2] > 0 ? Math.ceil(status[2] * 100 / count) : 0
        const neverShare = status[1] > 0 ? Math.ceil(status[1] * 100 / count) : 0
        const walkByShare = status[3] > 0 ? Math.ceil(status[3] * 100 / count) : 0
        let dataset = [
            Math.max(0, 100 - neverShare - lateShare - walkByShare),
            neverShare,
            lateShare,
            walkByShare,
        ]
        if (count === 0) {
            // Show yellow doughnut for empty sites
            dataset = [ 0, 1, 0, 0 ]
        }

        statusConfig.statusChartData = {
            datasets: [{
                labels: [ 'Rapporterat', 'Ny mätare', 'Rapporterar inte', 'Walk-by' ],
                backgroundColor: [ '#198754', '#ffc107', '#dc3545', '#339cff' ],
                data: dataset,
            }]
        }

        return { statusConfig, apartments }
    }

    sortApartments(apartments) {
        apartments = apartments.sort((a, b) => {
            if (a.type !== b.type) {
                return a.type - b.type
            }
            return helpers.compareStrings(a.number, b.number)
        })

        for (let a of apartments) {
            a.devices = a.devices.sort((a, b) => a.type - b.type)
        }
    }
}

export default new DevicesHelpers()