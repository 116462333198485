import api from './api'
import dateTime from '@/helpers/dateTime'
import ipRanges from '@/helpers/ipRanges.json'

function getInt(parts) {
    return Number.parseInt(parts[0])*256*256*256+Number.parseInt(parts[1])*256*256+Number.parseInt(parts[2])*256+Number.parseInt(parts[3])
}

function getIpOwner(ipAddress) {
    const ipParts = ipAddress.split('.')
    if (ipParts.length !== 4) {
        return ''
    }
    const ipInt = getInt(ipParts)
    for (const range of ipRanges) {
        if (ipInt >= range.from && ipInt <= range.to) {
            return ' (' + range.owner + ')'
        }
    }
    return ''    
}

function getGatewayLabel(serial) {
    if (!serial) {
        return 'Okänd'
    } else if (serial.startsWith('0006')) {
        return serial + ' (Elvaco CMe2100)'
    } else if (serial.startsWith('0012')) {
        return serial + ' (Elvaco CMi2110)'
    } else if (serial.startsWith('0148')) {
        return serial + ' (Elvaco CMe2100LTE)'
    } else if (serial.startsWith('0015')) {
        return serial + ' (Elvaco CMe3000)'
    } else if (serial.startsWith('0016')) {
        return serial + ' (Elvaco CMe3100)'
    } else if (serial.startsWith('1678')) {
        return serial + ' (PiiGAB 900S)'
    } else if (serial.startsWith('1681')) {
        return serial + ' (PiiGAB 900T)'
    } else {
        return serial
    }
}

class ImportLogService {
    async getSiteImports(siteId, start, stop) {
        const data = (await api.get(`/sites/${siteId}/imports`, { params: { start, stop }})).data

        let imports = []
        let index = 0
        for (const key in data.imports) {
            let address = data.imports[key].address
            if (address === 'unknown') {
                address = 'Okänd'
            } else {
                address += getIpOwner(address)
            }
            index++
            imports.push({
                index,
                timestamp: dateTime.getDateTimeString(new Date(data.imports[key].timestamp), { seconds: true }),
                gateway: getGatewayLabel(data.imports[key].gateway),
                address,
                unknown: data.imports[key].unknown,
                valid: data.imports[key].valid,
                invalid: data.imports[key].invalid,
                empty: data.imports[key].empty,
                userAgent: data.imports[key]['user-agent'],
            })
            if (index === 100) {
                break
            }
        }

        imports = imports.sort((a, b) => b.timestamp.localeCompare(a.timestamp))

        return {
            count: data.count,
            imports
        }
    }

    async getSiteLatestImport(siteId) {
        const res = (await api.get(`/sites/${siteId}/latest_import`)).data
        return res
    }
}

export default new ImportLogService()