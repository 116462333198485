<template>
    <div class="container pt-3 pb-3" style="background-color: white; border-bottom: 1px solid #ccc; border-left: 1px solid #ccc; border-right: 1px solid #ccc;">
        <div>
            <div class="row">
                <div class="col">
                    <label for="apartmentSelect" class="form-label">Mätplats</label>
                    <select id="apartmentSelect" class="form-select" @change="onApartmentChanged" v-model="form.apartment"
                        :disabled="apartments.length === 0">
                        <option v-for="apartment in apartments" :key="apartment.id" :value="apartment.id">
                            {{ apartment.number }}
                        </option>
                    </select>
                </div>
                <div class="col">
                    <label for="mpSelect" class="form-label">Mätare</label>
                    <select id="mpSelect" class="form-select" @change="onMpChanged" v-model="form.mp"
                        :disabled="mps.length === 0">
                        <option v-for="mp in mps" :key="mp.value" :value="mp.value">
                            {{ mp.label }}
                        </option>
                    </select>
                </div>
                <div class="col">
                    <label for="fieldSelect" class="form-label">Fält</label>
                    <select id="fieldSelect" class="form-select" @change="update" v-model="form.field"
                        :disabled="fields.length === 0">
                        <option v-for="field in fields" :key="field" :value="field">
                            {{ fieldLabels[field] }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-3">
                    <label for="periodSelect" class="form-label">Intervall / Period</label>
                    <select id="periodSelect" class="form-select" @change="update" v-model="form.period">
                        <option v-for="p in form.periods" :value="p.value">{{ p.label }}</option>
                    </select>
                </div>
                <div class="col-3" v-show="form.period == '15minsDay' || form.period == 'hoursDay'">
                    <label for="dateInput" class="form-label">Datum</label>
                    <input id="dateInput" type="date" class="form-control" @change="update" v-model="form.date" />
                </div>
                <div class="col-3" v-show="form.period == 'hoursWeek' || form.period == 'daysWeek' || form.period == 'daysMonth' || form.period == 'monthsYear'">
                    <label for="yearSelect" class="form-label">År</label>
                    <select id="yearSelect" class="form-select" @change="update" v-model="form.year">
                        <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                    </select>
                </div>
                <div class="col-3" v-show="form.period == 'daysMonth'">
                    <label for="monthSelect" class="form-label">Månad</label>
                    <select id="monthSelect" class="form-select" @change="update" v-model="form.month">
                        <option value="0">januari</option>
                        <option value="1">februari</option>
                        <option value="2">mars</option>
                        <option value="3">april</option>
                        <option value="4">maj</option>
                        <option value="5">juni</option>
                        <option value="6">juli</option>
                        <option value="7">augusti</option>
                        <option value="8">september</option>
                        <option value="9">oktober</option>
                        <option value="10">november</option>
                        <option value="11">december</option>
                    </select>
                </div>
                <div class="col-3" v-show="form.period == 'hoursWeek' || form.period == 'daysWeek'">
                    <label for="weekSelect" class="form-label">Vecka</label>
                    <select id="weekSelect" class="form-select" @change="update" v-model="form.week">
                        <option v-for="week in weeks" :key="week" :value="week">{{ week }}</option>
                    </select>
                </div>
                <div class="col">
                    <div>&nbsp;</div>
                    <button class="btn btn-outline-secondary mt-2" @click="previous" title="Föregående">&lt;</button>
                    &nbsp;
                    <button class="btn btn-outline-secondary mt-2" @click="next" title="Nästa">&gt;</button>
                </div>
                <div class="col text-end">
                    <div>&nbsp;</div>
                    <button class="btn btn-secondary mt-2" @click="download" :disabled="!this.form.field" title="Ladda ner data"><i class="bi bi-download"></i></button>
                </div>
            </div>
        </div>
        <div>
            <Bar v-if="fieldChartTypes[form.field] === 'bar'" :data="chartData" :options="chartOptions" />
            <Line v-if="fieldChartTypes[form.field] === 'line'" :data="chartData" :options="form.field === 'rf-level' ? rfLevelChartOptions : chartOptions" />
        </div>
    </div>
</template>

<script>
import dateTime from '@/helpers/dateTime'
import readingsService from '@/services/readingsService'
import { Bar, Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js'
import seriesHelpers from '@/helpers/seriesHelpers'
import helpers from '@/helpers/helpers'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement)

export default {
    components: { Bar, Line },
    props: { form: Object, apartmentsData: Array },
    data() {
        return {
            apartments: [],
            mps: [],
            fields: [],
            years: [],
            weeks: [],
            allMeasurements: [],
            allMeasurementsFields: {},
            chartType: null,
            chartData: { labels: [], datasets:[{data:[]}]},
            chartOptions: {
                locale: 'sv-SE',
                animation: {
                    duration: 0
                },
                scales: {
                    y: { beginAtZero: true }
                },
            },
            rfLevelChartOptions: {
                locale: 'sv-SE',
                animation: {
                    duration: 0
                },
                scales: {
                    y: { 
                        suggestedMin: -120,
                        beginAtZero: true,
                    }
                },
            },
            fieldLabels: {
                'volume': 'Volym (m³)',
                'volume-back': 'Volym bakåt (m³)',
                'energy': 'Energi (kWh)',
                'flow-temp': 'Tilloppstemperatur (°C)',
                'return-temp': 'Retur-temperatur (°C)',
                'diff-temp': 'ΔT (K)',
                'volume-flow': 'Flödesvolym (m³/h)',
                'power': 'Effekt (W)',
                'count': 'Radiator (enheter)',
                'rf-level': 'Signalstyrka (dBm)',
                'ext-temp': 'Temperatur (°C)',
                'relative-humidity': 'Relativ luftfuktighet (%)',
                'calculated-volume-flow': 'Beräknad flödesvolym (m³/h)',
            },
            fieldChartTypes: {
                'volume': 'bar',
                'volume-back': 'bar',
                'energy': 'bar',
                'count': 'bar',
                'flow-temp': 'line',
                'return-temp': 'line',
                'power': 'line',
                'diff-temp': 'line',
                'ext-temp': 'line',
                'relative-humidity': 'line',
                'rf-level': 'line',
                'volume-flow': 'line',
                'calculated-volume-flow': 'line',
            },
        }
    },
    methods: {
        async onApartmentChanged() {
            let mps = []
            if (!isNaN(this.form.apartment)) {
                const apartment = this.apartments.find(a => a.id == this.form.apartment)

                let measurements = {}
                for (const mp of apartment.mps) {
                    if (!(mp.measurement in measurements)) {
                        measurements[mp.measurement] = { count: 0, mps: [] }
                    }
                    measurements[mp.measurement].count++
                    measurements[mp.measurement].mps.push(mp)
                }

                for (const measurement in measurements) {
                    if (measurements[measurement].count > 1) {
                        mps.push({ value: measurement, measurement, label: 'Alla ' + seriesHelpers.getMeasurementLabel(measurement) })
                    }
                    for (const mp of measurements[measurement].mps) {
                        mps.push({ value: mp.id, measurement, label: seriesHelpers.getMeasurementLabel(measurement) + ' ' + mp.idNumber })
                    }
                }
            } else {
                for (const measurement of this.allMeasurements) {
                    mps.push({ value: measurement, measurement, label: seriesHelpers.getMeasurementLabel(measurement) })
                }
            }

            this.mps = mps

            if (mps.length === 1) {
                this.form.mp = mps[0].value
                this.onMpChanged(true)
            } else if (this.form.mp) {
                const current = mps.find(m => m.value == this.form.mp)

                if (current) {
                    this.update()
                    this.onMpChanged(true)
                } else if (mps.find(m => m.value == this.form.measurement)) {
                    this.form.mp = this.form.measurement
                    this.onMpChanged(true)
                } else {
                    const other = mps.find(m => m.measurement == this.form.measurement)
                    if (other) {
                        this.form.mp = other.value
                        this.onMpChanged(true)
                    } else {
                        this.fields = []
                        this.form.mp = null
                        this.form.field = null
                    }
                }
            }
        },
        onMpChanged(keepSameField = false) {
            if (!isNaN(this.form.mp) && !isNaN(this.form.apartment)) {
                const apartment = this.apartments.find(a => a.id == this.form.apartment)
                const mp = apartment.mps.find(m => m.id == this.form.mp)
                this.form.measurement = mp.measurement
                this.fields = mp.fields
                if ((this.form.measurement === 'cold_water' || this.form.measurement === 'hot_water') && !this.fields.includes('volume-flow')) {
                    this.fields.push('calculated-volume-flow')
                }
                if (!(keepSameField && mp.fields.find(f => f == this.form.field))) {
                    this.form.field = seriesHelpers.getMeasurementDefaultField(mp.measurement)
                }
            } else {
                this.form.measurement = this.form.mp
                this.fields = this.allMeasurementsFields[this.form.mp]
                this.form.field = seriesHelpers.getMeasurementDefaultField(this.form.mp)
            }
            this.update()
        },
        async previous() {
            switch (this.form.period) {
                case '15minsDay':
                case 'hoursDay':
                    const date = new Date(this.form.date + 'T00:00')
                    date.setDate(date.getDate() - 1)
                    this.form.date = dateTime.getDateString(date)
                    break
                case 'hoursWeek':
                case 'daysWeek':
                    if (this.form.week == 1) {
                        this.form.year--
                        this.form.week = dateTime.getWeeksInYear(this.form.year)
                        this.weeks = []
                        for (let i = 1; i <= this.form.week; i++) {
                            this.weeks.push(i)
                        }
                    } else {
                        this.form.week--
                    }
                    break
                case 'daysMonth':
                    if (this.form.month == 0) {
                        this.form.year--
                        this.form.month = 11
                    } else {
                        this.form.month--
                    }
                    break
                case 'monthsYear':
                    this.form.year--
                    break
                default:
                    return
            }
            this.update()
        },
        async next() {
            switch (this.form.period) {
                case '15minsDay':
                case 'hoursDay':
                    const date = new Date(this.form.date + 'T00:00')
                    date.setDate(date.getDate() + 1)
                    this.form.date = dateTime.getDateString(date)
                    break
                case 'hoursWeek':
                case 'daysWeek':
                    if (this.form.week == this.weeks.length) {
                        this.form.year++
                        this.form.week = dateTime.getWeeksInYear(this.form.year)
                        this.weeks = []
                        for (let i = 1; i <= this.form.week; i++) {
                            this.weeks.push(i)
                        }
                    } else {
                        this.form.week++
                    }
                    break
                case 'daysMonth':
                    if (this.form.month == 11) {
                        this.form.year++
                        this.form.month = 0
                    } else {
                        this.form.month++
                    }
                    break
                case 'monthsYear':
                    this.form.year++
                    break
                default:
                    return
            }
            this.update()
        },
        async update() {
            let params

            if (!this.form.measurement || !this.form.field) {
                return
            }

            let periodMillis = null

            switch (this.form.period) {
                case '15minsDay':
                    params = {
                        period: 'day',
                        interval: '15min',
                        start: new Date(this.form.date + 'T00:00'),
                    }
                    periodMillis = 15 * 60 * 1000
                    break
                case 'hoursDay':
                    params = {
                        period: 'day',
                        interval: 'hour',
                        start: new Date(this.form.date + 'T00:00'),
                    }
                    periodMillis = 60 * 60 * 1000
                    break
                case 'hoursWeek':
                    params = {
                        period: 'week',
                        interval: 'hour',
                        start: dateTime.getMonday(this.form.year, this.form.week),
                    }
                    periodMillis = 60 * 60 * 1000
                    break
                case 'daysWeek':
                    params = {
                        period: 'week',
                        interval: 'day',
                        start: dateTime.getMonday(this.form.year, this.form.week),
                    }
                    periodMillis = 24 * 60 * 60 * 1000
                    break
                case 'daysMonth':
                    params = {
                        period: 'month',
                        interval: 'day',
                        start: new Date(this.form.year, this.form.month, 1),
                    }
                    periodMillis = 24 * 60 * 60 * 1000
                    break
                case 'monthsYear':
                    params = {
                        period: 'year',
                        interval: 'month',
                        start: new Date(this.form.year, 0, 1),
                    }
                    periodMillis = 30 * 24 * 60 * 60 * 1000
                    break
                default:
                    return console.error('unknown period')
            }

            if (params.period === 'week') {
                const maxWeek = dateTime.getWeeksInYear(this.form.year)
                this.weeks = []
                for (let i = 1; i <= maxWeek; i++) {
                    this.weeks.push(i)
                }
            }

            if (!isNaN(this.form.mp)) {
                params['mp'] = this.form.mp
            } else if (!isNaN(this.form.apartment)) {
                params['apartment'] = this.form.apartment
            } else {
                if (this.form.apartment.startsWith('type')) {
                    const type = Number.parseInt(this.form.apartment.substring(4))
                    if (type === 1) {
                        let exclude = []
                        for (const apartment of this.apartments) {
                            if (isNaN(apartment.id)) {
                                continue
                            }
                            if (apartment.type != type) {
                                exclude.push(apartment.id)
                            }
                        }
                        params.exclude = exclude.join(',')
                    } else {
                        let include = []
                        for (const apartment of this.apartments) {
                            if (isNaN(apartment.id)) {
                                continue
                            }
                            if (apartment.type == type) {
                                include.push(apartment.id)
                            }
                        }
                        params.include = include.join(',')
                    }
                }
                params['site'] = this.form.site
            }

            if (this.form.field === 'calculated-volume-flow') {
                params.series = `${this.form.measurement}.volume`
            } else {
                params.series = `${this.form.measurement}.${this.form.field}`
            }

            const data = (await readingsService.getSiteStatistics(this.form.site, params)).data

            let labels = []
            let datasets = []

            for (const series in data) {
                let dataset = {
                    label: seriesHelpers.getLabel(params.series),
                    backgroundColor: seriesHelpers.getColor(params.series),
                    data: [],
                }
                datasets.push(dataset)

                for (const timestamp in data[series]) {
                    labels.push(seriesHelpers.getXAxisLabel(new Date(timestamp), params.interval, params.period))
                    if (new Date(timestamp) > new Date()) {
                        continue
                    } else if (this.form.field === 'calculated-volume-flow') {
                        if (new Date(timestamp).getTime() + periodMillis > new Date().getTime()) {
                            const hours = (new Date().getTime() - new Date(timestamp).getTime()) / 1000 / 60 / 60
                            dataset.data.push(data[series][timestamp] / hours)
                        } else {
                            dataset.data.push(data[series][timestamp] / (periodMillis / 1000 / 60 / 60))
                        }
                    } else {
                        dataset.data.push(data[series][timestamp])
                    }
                }
            }

            this.chartData = { labels, datasets }
        },
        download() {
            let lines = []

            const deviceLabel = seriesHelpers.getMeasurementLabel(this.form.measurement)
            const fieldLabel = seriesHelpers.getFieldLabel(this.form.field)
            lines.push(`Mätare:;${deviceLabel}`)
            lines.push(`Fält:;${fieldLabel}`)

            let header = ''
            let filename = ''
            const date = new Date(this.form.date + 'T00:00')
            switch (this.form.period) {
                case '15minsDay':
                    header = 'Tid'
                    filename = `kvartsvärde ${dateTime.getDateString(date)}`
                    lines.push(`Datum:;${dateTime.getDateString(date)}`)
                    break
                case 'hoursDay':
                case 'hoursWeek':
                    header = 'Timme'
                    filename = `timvärde ${dateTime.getDateString(date)}`
                    lines.push(`Datum:;${dateTime.getDateString(date)}`)
                    break
                case 'daysWeek':
                    header = 'Dag'
                    filename = `dygnsvärde v${dateTime.getWeekNumber(date)}`
                    lines.push(`Vecka:;${dateTime.getWeekNumber(date)}`)
                    break
                case 'daysMonth':
                    header = 'Dag'
                    filename = `dygnsvärde ${dateTime.getMonthYearString(this.form.year, this.form.month)}`
                    lines.push(`Månad:;${dateTime.getMonthYearString(this.form.year, this.form.month)}`)
                    break
                case 'monthsYear':
                    header = 'Månad'
                    filename = `månadsvärde ${this.form.year}`
                    lines.push(`År:;${this.form.year}`)
                    break
            }

            lines.push('')
            lines.push(`${header};Värde;Enhet`)

            const unit = seriesHelpers.getFieldUnit(this.form.field)
            const decimals = seriesHelpers.getFieldPrecision(this.form.field)

            for (let i = 0; i < this.chartData.labels.length; i++) {
                if (this.chartData.datasets[0].data.length <= i) {
                    lines.push(`${this.chartData.labels[i]};;${unit}`)
                    continue
                }
                const value = this.chartData.datasets[0].data[i].toLocaleString('sv', {
                    useGrouping: false,
                    minimumFractionDigits: decimals,
                    maximumFractionDigits: decimals,
                })
                lines.push(`${this.chartData.labels[i]};${value};${unit}`)
            }

            const blob = new Blob([lines.join('\r\n')])
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = `${deviceLabel} ${fieldLabel} ${filename}.csv`
            link.click()
            URL.revokeObjectURL(link.href)
        },
    },
    mounted() {
        let now = new Date()
        let years = []
        for (let i = 0; i < 5; i++) {
            years.push((now.getFullYear() - i).toString())
        }
        this.years = years

        let apartmentTypesCount = {}
        let apartments = []
        let measurements = {}

        // Copy apartments and mp to drop
        for (const a of this.apartmentsData) {
            let apartment = {
                id: a.id,
                number: a.number,
                address: a.address,
                type: a.type,
                mps: [],
            }

            if (!(a.type in apartmentTypesCount)) {
                apartmentTypesCount[a.type] = 0
            }

            apartmentTypesCount[a.type]++

            apartments.push(apartment)

            for (const device of a.devices) {
                if (!(device.measurement in measurements)) {
                    measurements[device.measurement] = {}
                }

                let mp = {
                    id: device.measuringPointId,
                    measurement: device.measurement,
                    idNumber: device.idNumber,
                    fields: [],
                }

                for (const field in device.fields) {
                    if (field == 'status') {
                        continue
                    }
                    mp.fields.push(field)
                    if (!(field in measurements[device.measurement])) {
                        measurements[device.measurement][field] = true
                    }
                }

                apartment.mps.push(mp)
            }
        }

        let measurementsArray = []
        let measurementFields = {}
        for (let measurement in measurements) {
            measurementsArray.push(measurement)
            measurementFields[measurement] = []
            for (let field in measurements[measurement]) {
                measurementFields[measurement].push(field)
            }
        }
        this.allMeasurements = measurementsArray
        this.allMeasurementsFields = measurementFields

        if (apartments.length === 1) {
            this.apartments = apartments
            this.form.apartment = apartments[0].id
            this.onApartmentChanged()
        } else {
            apartments = apartments.sort((a, b) => helpers.compareStrings(a.number, b.number))

            let groups = [{ id: 'all', number: 'Alla' }]

            if (Object.keys(apartmentTypesCount).length > 1) {
                for (const type in apartmentTypesCount) {
                    if (type == 1) {
                        if (apartmentTypesCount[type] > 1) {
                            groups.push({ id: 'type1', number: `Bostäder` })
                        }
                    } else if (type == 2) {
                        if (apartmentTypesCount[type] > 1) {
                            groups.push({ id: 'type2', number: `Undercentra` })
                        }
                    } else if (type == 3) {
                        if (apartmentTypesCount[type] > 1) {
                            groups.push({ id: 'type3', number: `Gemensamma utrymmen` })
                        }
                    } else if (type == 4) {
                        if (apartmentTypesCount[type] > 1) {
                            groups.push({ id: 'type4', number: `Annat` })
                        }
                    }
                }
            }
            this.apartments = groups.concat(apartments)
            this.mps = []
            this.fields = []

            if (this.apartments.find(a => a.id == this.form.apartment)) {
                this.onApartmentChanged()
            }
        }
    },
}
</script>