<template>
    <div class="box mb-3">
        <div :class="'head ' + seriesClass[series]">
            <h3 class="pt-3 ps-3 pe-3 pb-2">{{ seriesLabels[series] }}</h3>
        </div>
        <div>
            <div class="container">
                <div class="row">
                    <div>MÅNADSFÖRBRUKNING</div>
                </div>
                <div class="row">
                    <div class="col">
                        <div>
                            <span class="fs-1">{{ lastMonth ? Math.round(lastMonth) : '-' }}</span>
                            &nbsp;<span class="fs-6 text-muted">{{ seriesUnits[series] }}</span>
                        </div>
                        <div class="text-muted small">förra månaden</div>
                    </div>
                    <div class="col">
                        <div>
                            <span class="fs-1">{{ thisMonth ? Math.round(thisMonth) : '-' }}</span>
                            &nbsp;<span class="fs-6 text-muted">{{ seriesUnits[series] }}</span>
                        </div>
                        <div class="text-muted small">denna månad</div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div>SNITT PER DYGN</div>
                </div>
                <div class="row mb-4">
                    <div class="col-6">
                        <div>
                            <span class="fs-1">{{ lastMonthAvg ? Math.round(lastMonthAvg) : '-' }}</span>
                            &nbsp;<span class="fs-6 text-muted">{{ seriesUnits[series] }}</span>
                        </div>
                        <div class="text-muted small">förra månaden</div>
                    </div>
                    <!-- <div class="col-2">
                        <div v-show="false">
                            <h1 v-show="thisMonthAvg > lastMonthAvg" class="bi bi-arrow-up-right text-danger" title="Dygnssnittet är högre denna månaden"></h1>
                            <h1 v-show="thisMonthAvg < lastMonthAvg" class="bi bi-arrow-down-right text-success" title="Dygnssnittet är lägre denna månaden"></h1>
                        </div>
                    </div> -->
                    <div class="col-6">
                        <div>
                            <span class="fs-1">{{ thisMonthAvg ? Math.round(thisMonthAvg) : '-' }}</span>
                            &nbsp;<span class="fs-6 text-muted">{{ seriesUnits[series] }}</span>
                        </div>
                        <div class="text-muted small">denna månad</div>
                    </div>
                </div>
                <div class="row">
                    <div>DYGNSFÖRBRUKNING</div>
                </div>
                <div class="row chart">
                    <bar :data="chartData" :options="chartOptions" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Chart as ChartJS, Title, ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js'
import { Bar } from 'vue-chartjs'

ChartJS.register(Title, Tooltip, Legend, ArcElement, BarElement, CategoryScale, LinearScale, PointElement, LineElement)

export default {
    components: { Bar },
    props: {
        series: String,
        thisMonth: Number,
        lastMonth: Number,
        thisMonthAvg: Number,
        lastMonthAvg: Number,
        chartData: Object,
    },
    data() {
        return {
            chartOptions: {
                locale: 'sv-SE',
                maintainAspectRatio: false,
                animation: {
                    duration: 0
                },
            },
            // Dictionaries
            seriesLabels: {
                'cold_water.volume': 'Kallvatten',
                'hot_water.volume': 'Varmvatten',
                'heat.energy': 'Värmeenergi',
                'heat.volume': 'Hetvattensvolym',
                'electricity.energy': 'Elektricitet',
                'hca.count': 'Radiator',
                'cold.energy': 'Kyl-energi',
            },
            seriesUnits: {
                'cold_water.volume': 'l',
                'hot_water.volume': 'l',
                'heat.energy': 'kWh',
                'heat.volume': 'l',
                'electricity.energy': 'kWh',
                'hca.count': '',
                'cold.energy': 'kWh',
            },
            seriesClass: {
                'cold_water.volume': 'cold_water',
                'hot_water.volume': 'hot_water',
                'heat.energy': 'heat',
                'heat.volume': 'heat_volume',
                'electricity.energy': 'electricity',
                'hca.count': 'hca',
                'cold.energy': 'cold',
            },
        }
    },
}
</script>

<style>
.box {
    width: 300px;
    border-radius: 1em;
    border: 1px solid #ccc;
    box-shadow: 5px 5px 5px #00000030;
    background-color: #fff;
}
.head {
    border-top-right-radius: 1em;
    border-top-left-radius: 1em;
    padding-bottom: .1em;
    text-align: center;
    text-transform: uppercase;
}
.chart {
    height: 240px
}
.cold_water {
    color: #2d93dd;
}
.hot_water {
    color: #d32734;
}
.heat {
    color: #ffaa00;
}
.heat_volume {
    color: #ff6600;
}
.electricity {
    color: #ffee00;
}
.hca {
    color: #cc33ff;
}
.cold {
    color: #2d93dd;
}
</style>