import api from './api'

class ApartmentsService {
    getApartments(siteId) {
        return api.get(`/sites/${siteId}/apartments`)
    }

    getApartmentInfo(apartmentId) {
        return api.get(`/apartments/${apartmentId}/info`)
    }

    createApartment(apartment) {
        return api.post(`/sites/${apartment.site_id}/apartments`, apartment)
    }

    updateApartment(id, apartment) {
        return api.put(`/apartments/${id}`, apartment)
    }

    deleteApartment(id) {
        return api.delete(`/apartments/${id}`)
    }
}

export default new ApartmentsService()