<template>
    <div class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Resultat av import</h4>
                    <button type="button" class="btn-close" aria-label="Close" @click="$emit('close')"></button>
                </div>
                <div class="modal-body">
                    <div v-show="data.errors && data.errors.length > 0" class="text-danger mt-3">
                        Det finns fel i filen:
                        <ul>
                            <li v-for="error in data.errors">{{ error }}</li>
                        </ul>
                    </div>
                    <div v-show="data.changes && data.changes.length > 0" class="text-success mt-3">
                        Följande ändringar gjordes:
                        <ul>
                            <li v-for="change in data.changes">{{ change }}</li>
                        </ul>
                    </div>
                    <div v-show="data.changes && data.errors && data.errors.length === 0 && data.changes.length === 0">
                        Inga ändringar fanns
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="$emit('close')">Stäng</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: { data: Object },
    emits: [ 'close' ],
}
</script>