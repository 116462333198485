const months = [ 'jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec' ]

const mpTypeMeasurement = {
    1: 'cold_water',
    2: 'hot_water',
    3: 'heat',
    4: 'electricity',
    5: 'hca',
    6: 'ambiance',
    7: 'cold',
}

const measurementSortOrder = {
    'cold_water': 1,
    'hot_water': 2,
    'heat': 3,
    'electricity': 4,
    'hca': 5,
    'ambiance': 6,
    'cold': 7,
}

const defaultField = {
    'cold_water': 'volume',
    'hot_water': 'volume',
    'heat': 'energy',
    'electricity': 'energy',
    'hca': 'count',
    'ambiance': 'ext-temp',
    'cold': 'energy',
}

const primaryFields = {
    'cold_water': ['volume'],
    'hot_water': ['volume'],
    'heat': ['energy'],
    'electricity': ['energy'],
    'hca': ['count'],
    'ambiance': ['ext-temp', 'relative-humidity'],
    'cold': ['energy'],
}

const fieldUnits = {
    'volume': 'm³',
    'volume-back': 'm³',
    'energy': 'kWh',
    'ext-temp': '°C',
    'relative-humidity': '%',
    'flow-temp': '°C',
    'return-temp': '°C',
    'power': 'kW',
    'diff-temp': 'K',
    'rf-level': 'dBm',
    'volume-flow': 'm³/h',
    'count': 're',
}

const fieldPrecision = {
    'volume': 3,
    'volume-back': 3,
    'energy': 0,
    'ext-temp': 1,
    'relative-humidity': 1,
    'flow-temp': 1,
    'return-temp': 1,
    'power': 1,
    'diff-temp': 1,
    'count': 0,
    'rf-level': 0,
    'volume-flow': 3,
}

const fieldLabels = {
    'volume': 'Volym',
    'volume-back': 'Bakåt-volym',
    'energy': 'Energi',
    'flow-temp': 'Tilloppstemperatur',
    'return-temp': 'Retur-temperatur',
    'power': 'Effekt',
    'ext-temp': 'Temperatur',
    'diff-temp': 'ΔT',
    'relative-humidity': 'Relativ luftfuktighet',
    'rf-level': 'Signalstyrka',
    'volume-flow': 'Flödesvolym',
    'status': 'Status',
    'count': 'Enheter',
    'calculated-volume-flow': 'Beräknad flödesvolym',
}

const fieldShortLabels = {
    'volume': 'Volym',
    'volume-back': 'Bakåt',
    'energy': 'Energi',
    'flow-temp': 'Tillopp.',
    'return-temp': 'Retur.',
    'power': 'Effekt',
    'ext-temp': 'Temp.',
    'diff-temp': 'ΔT',
    'relative-humidity': 'Rel. luftfukt.',
    'rf-level': 'Signal',
    'volume-flow': 'Flöde',
    'status': 'Status',
    'count': 'Enheter',
}

const setLabels = {
    'cold_water.volume': 'Kallvatten (m³)',
    'cold_water.volume-back': 'Kallvatten bakåt (m³)',
    'hot_water.volume': 'Varmvatten (m³)',
    'heat.energy': 'Värmeenergi (kWh)',
    'flow-temp': 'Tilloppstemperatur (°C)',
    'return-temp': 'Retur-temperatur (°C)',
    'power': 'Effekt (W)',
    'diff-temp': 'ΔT (K)',
    'volume-flow': 'Flödesvolym (m³/h)',
    'electricity.energy': 'El-energi (kWh)',
    'hca.count': 'Radiator (enheter)',
    'cold.energy': 'Kyl-energi (kWh)',
    'heat.volume': 'Hetvatten (m³)',
    'cold.volume': 'Kylvatten (m³)',
    'rf-level': 'Signalstyrka (dBm)',
    'ambiance.ext-temp': 'Temperatur (°C)',
    'ambiance.relative-humidity': 'Relativ luftfuktighet (%)',
}

const measurementLabels = {
    'cold_water': 'Kallvatten',
    'hot_water': 'Varmvatten',
    'heat': 'Värmemätare',
    'electricity': 'El-mätare',
    'hca': 'Radiatormätare',
    'ambiance': 'Rumsgivare',
    'cold': 'Kylnings-mätare',
}

const setLabelsUpper = {
    'cold_water.volume': 'KALLVATTEN (m³)',
    'cold_water.volume-back': 'KALLVATTEN BAKÅT (m³)',
    'hot_water.volume': 'VARMVATTEN (m³)',
    'heat.energy': 'VÄRMEENERGI (kWh)',
    'flow-temp': 'TILLOPPSTEMPERATUR (°C)',
    'return-temp': 'RETUR-TEMPERATUR (°C)',
    'power': 'EFFEKT (W)',
    'diff-temp': 'ΔT (K)',
    'volume-flow': 'FLÖDESVOLYM (m³/h)',
    'electricity.energy': 'EL-ENERGI (kWh)',
    'hca.count': 'RADIATOR (enheter)',
    'cold.energy': 'KYL-ENERGI (kWh)',
    'heat.volume': 'HETVATTEN (m³)',
    'cold.volume': 'KYLVATTEN (m³)',
    'rf-level': 'SIGNALSTYRKA (dBm)',
    'ambiance.ext-temp': 'TEMPERATUR (°C)',
    'ambiance.relative-humidity': 'RELATIV LUFTFUKTIGHET (%)',
}

const setColors = {
    'cold_water.volume': '#2d93dd',
    'cold_water.volume-back': '#1d73ad',
    'hot_water.volume': '#d32734',
    'heat.energy': '#ffaa00',
    'electricity.energy': '#28c641',
    'hca.count': '#cc33ff',
    'cold.energy': '#2d93dd',
    'heat.volume': '#ff6600',
    'cold.volume': '#ff6600',
    'flow-temp': '#ff0000',
    'return-temp': '#0000ff',
    'power': '#00ccff',
    'diff-temp': '#ff00ff',
    'rf-level': '#000000',
    'ext-temp': '#d32734',
    'relative-humidity': '#2d93dd',
    'volume-flow': '#ff66ff',
}

const daysOfWeek = {
    0: 'sön',
    1: 'mån',
    2: 'tis',
    3: 'ons',
    4: 'tor',
    5: 'fre',
    6: 'lör',
}

class SeriesHelpers {
    getMeasurementFromType(type) {
        return mpTypeMeasurement[type]
    }

    getMeasurementLabel(measurement) {
        return measurementLabels[measurement]
    }

    getFieldLabel(field) {
        return fieldLabels[field] ? fieldLabels[field] : field
    }

    getFieldShortLabel(field) {
        return fieldShortLabels[field] ? fieldShortLabels[field] : field
    }

    getFieldUnit(field) {
        return fieldUnits[field] ? fieldUnits[field] : ''
    }

    getMeasurementDefaultUnit(measurement) {
        return fieldUnits[defaultField[measurement]]
    }

    getMeasurementDefaultField(measurement) {
        return defaultField[measurement]
    }

    getMeasurementPrimaryFields(measurement) {
        return primaryFields[measurement]
    }

    getFieldPrecision(field) {
        return fieldPrecision[field]
    }

    getMeasurementSortOrder(measurement) {
        return measurementSortOrder[measurement]
    }

    getLabel(series, options = { upperCase: false }) {
        const directory = options.upperCase ? setLabelsUpper : setLabels

        if (directory[series]) {
            return directory[series]
        }
        const field = series.split('.')[1]
        if (directory[field]) {
            return directory[field]
        }
        return 'Okänd'
    }

    getColor(series) {
        if (setColors[series]) {
            return setColors[series]
        }
        const field = series.split('.')[1]
        if (setColors[field]) {
            return setColors[field]
        }
        return ''
    }

    getField(series) {
        return series.split('.')[1]
    }

    getXAxisLabel(date, interval, period = null) {
        switch (interval) {
            case '15min':
                return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`
            case 'hour':
                if (period === 'week') {
                    return `${date.getHours().toString().padStart(2, '0')} ${date.getDate().toString()}/${(date.getMonth() + 1)}`
                }
                return `${date.getHours().toString().padStart(2, '0')}`
            case 'day':
                if (period === 'week') {
                    return `${daysOfWeek[date.getDay()]} ${date.getDate().toString()}/${(date.getMonth() + 1).toString()}`
                }
                return `${date.getDate().toString()}`
            case 'month':
                return months[date.getMonth()]
            default:
                return '??'
        }
    }

    formatValue(measurement, value) {
        if (typeof value === 'undefined') {
            return 'Saknas'
        }
        if (measurement === 'cold_water' || measurement === 'hot_water') {
            return Number.parseFloat(value).toLocaleString('sv', { minimumFractionDigits: 3, maximumFractionDigits: 3 })
        } else {
            return Number.parseFloat(value).toLocaleString('sv', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
        }
    }

    formatFieldValue(field, value) {
        if (typeof value === 'undefined') {
            return '-'
        }
        if (field === 'status') {
            return Number.parseInt(value).toString(16).padStart(2, '0')
        }
        const n = fieldPrecision[field]
        return Number.parseFloat(value).toLocaleString('sv', { minimumFractionDigits: n, maximumFractionDigits: n })
    }    
}

export default new SeriesHelpers()